<div id="container-models-item">

<div id="container" class="fadeIn def_back gallery-models-item">
  <div id="container-border"></div>
  <div id="container-bottom"></div>
<!--<app-modal [visible]="true"-->
<!--           [fullscreen]="isFullScreen"-->
<!--           (visibleChange)="close()">-->
  <div *ngIf="!item && !galleryError && !galleryNotFound" class="lds-css">
    <app-loading></app-loading>
  </div>
  <div *ngIf="!item && galleryError" class="gallery-error">
    <i class="far fa-times-circle"></i>
    {{'errors.UNKNOWN' | translate}}
  </div>
  <div *ngIf="!item && galleryNotFound" class="gallery-error">
    <h4 class="error-code">404</h4>
    <p>{{'errors.NOT_FOUND' | translate}}</p>
  </div>
  <div #view *ngIf="item">
    <div id="container-item" class="gallery-models-item">
      <i id="type-item" class="mobile-infos fas fa-2x fa-{{GALLERY_MODELS_TYPES[item.type]}} {{item.type}}"></i>
      <h1 id="title-item" class="row-item def_back"
          [innerHTML]="('general.lang' | translate) == 'it' ? item.title : item.titleEn"></h1>
      <div id="body-item">
        <div id="wrapper-item">

          <picture>
            <source [srcset]="PUBLIC_MODELS_IMAGES_3D + 'webp/' + item.name
                + (itemNumber > 1 ? '_'+itemNumber : '') + GALLERY_FILE_EXT_NEW" type="image/webp">
            <img draggable="false" class="row-item" [src]="PUBLIC_MODELS_IMAGES_3D + item.name
                + (itemNumber > 1 ? '_'+itemNumber : '') + GALLERY_FILE_EXT"
                 (swipeleft)="(itemNumber < item.mNum ? changeItem(item.name, (itemNumber+1)) : null)"
                 (swiperight)="(itemNumber > 1 ? changeItem(item.name, (itemNumber-1)) : null)"
                 id="imgItem" [alt]="'3D ' + item.titleEn"
                 [width]="item.width ? item.width : item.size" height="100%"
                 [style.width.px]="item.width ? item.width : item.size">
          </picture>
          <!--<img draggable="false" class="row-item" [src]="PUBLIC_MODELS_IMAGES_3D + item.name
                + (itemNumber > 1 ? '_'+itemNumber : '') + GALLERY_FILE_EXT"
               (swipeleft)="(itemNumber < item.mNum ? changeItem(item.name, (itemNumber+1)) : null)"
               (swiperight)="(itemNumber > 1 ? changeItem(item.name, (itemNumber-1)) : null)"
               id="imgItem" alt="" [style.width.px]="item.width ? item.width : item.size" />-->
          <!-- TODO aggiungere di nuovo col sistema a scorrimento orizzontale.
          <i id="expand" class="fa-solid fa-expand d-sm-none d-md-block"
             *ngIf="showFullScreenButton && !isFullScreen && (item.width ? item.width : item.size) > 700" (click)="openFullscreen()"></i>
          <i id="compress" class="fa-solid fa-compress d-sm-none d-md-block"
             *ngIf="showFullScreenButton && isFullScreen" (click)="closeFullscreen()"></i>
          <i id="expand-mobile" class="fa-solid fa-expand d-sm-block d-md-none"
             *ngIf="showFullScreenButton && !isFullScreen && (item.width ? item.width : item.size) > 700" (click)="openFullscreen(true)"></i>
          <i id="compress-mobile" class="fa-solid fa-compress d-sm-block d-md-none"
             *ngIf="showFullScreenButton && isFullScreen" (click)="closeFullscreen(true)"></i>
             -->
        </div>
        <div id="item-footer">
          <div id="button-container" *ngIf="item.mNum > 1"> <!--  [style.maxWidth.px]="item.size" -->
            <ng-container *ngFor="let itemsNumber of itemsArray">
              <button type="button" class="input-link list"
                      [disabled]="itemNumber === itemsNumber"
                      (click)="changeItem(item.name, itemsNumber)">
                <!--<img draggable="false" [src]="PUBLIC_MODELS_IMAGES_3D + item.name
                      + (itemsNumber > 1 ? ('_' + itemsNumber) : '') + GALLERY_FILE_EXT"
                     class="input-icon-img" alt="" height="50px" width="50px" />-->
                <picture>
                  <source [srcset]="PUBLIC_MODELS_IMAGES_3D + 'webp/' + item.name
                      + (itemsNumber > 1 ? ('_' + itemsNumber) : '') + '.webp'" type="image/webp">
                  <img draggable="false" [src]="PUBLIC_MODELS_IMAGES_3D + item.name
                      + (itemsNumber > 1 ? ('_' + itemsNumber) : '') + GALLERY_FILE_EXT"
                       class="input-icon-img" [alt]="'3D ' + item.titleEn + itemsNumber" width="50px">
                </picture>
                <span class="input-icon-text">{{itemsNumber}}</span>
              </button>
            </ng-container>
          </div>
        </div>
      </div>

      <!--/************** INFOS ***********/-->
      <div class="row-item row-border-top row mobile-infos">
        <div class="col-12 col-md-7 mb-md-0 text-md-start text-center g-0 gx-sm-2" [class.mb-3]="item?.collection || item?.lods">
          <button type="button" (click)="sendLike(item.name)" [class.disabled]="isDisabledLike" tabindex="-1"
                  class="info-links like-link" triggers="hover" placement="top" [autoClose]="'outside'"
             ngbTooltip="{{'general.like' | translate}}">
            <span class="fa-heart" [ngClass]="isDisabledLike ? 'fas' : 'far'"
                  [class.clicked]="isClickedLike"></span>
            <span class="like-count">{{ item?.likes }}</span>
          </button>
          <a class="info-links download-link" [href]="item?.downloadLinks" *ngIf="item?.downloadLinks" target="_blank"
             triggers="hover" placement="top" [autoClose]="'outside'"
             ngbTooltip="{{'models.linkNew' | translate}}">
            <i class="fas fa-download"></i>
            <span class="download-text">{{'general.download' | translate}}</span>
            <!--            <div class="get-link" [innerHTML]="'models.link' | translate: {link: item.downloadLinks}">-->
          </a>
          <button type="button" class="info-links share-link" (click)="onShare()" [class.shared]="isShared"
             triggers="hover" placement="top" [autoClose]="'outside'"
             ngbTooltip="{{'general.share' | translate}}">
            <i class="fas" [ngClass]="isShared ? 'fa-check' : 'fa-share'"></i>
            <span class="share-text" *ngIf="!isShared">{{'general.share' | translate}}</span>
            <span class="share-text shared-text" *ngIf="isShared">{{'general.shared' | translate}}</span>
            <!--            <div class="get-link" [innerHTML]="'models.link' | translate: {link: item.downloadLinks}">-->
          </button>
        </div>
        <div class="col-12 col-md-5 text-md-end text-center" *ngIf="item?.collection || item?.lods">
          <!--  *ngIf="item.collection" -->
          <span class="info-links collection-icon" triggers="click"
                placement="top" [autoClose]="'outside'" ngbTooltip="{{'models.collection' | translate}}"
                *ngIf="item?.collection">Collection</span>
          <span class="info-links lods-icon" *ngIf="item?.lods"
                triggers="click" placement="top" [autoClose]="'outside'"
                ngbTooltip="{{ 'models.lodsTooltip' | translate }}">
            {{ 'models.lods' | translate }}</span>
        </div>
      </div>

      <!--/************** INFOS ***********/-->
      <div class="row-item row-border-top row" *ngIf="item.description">
        <h3 class="mini-titles" [innerHTML]="'models.descriptionTitle' | translate"></h3>
        <p class="col-12 description"
           [innerHTML]="('general.lang' | translate) == 'it' ? item.description : item.descriptionEn"></p>
      </div>

      <!--/*********** TAGS ***********/-->
      <div class="row-item" [class.row-border-top]="!item.description">

        <!--/************** BREADCRUMBS ***********/-->
        <app-breadcrumbs [nodes]="[{label: '3D Models', url: 'gallery-models'},
            {label: '3D ' + (('menu.sez3dModels' + (item.type | titlecase)) | translate), url: 'gallery-models/' + item.type},
            {label: ('general.lang' | translate) == 'it' ? item.title : item.titleEn}]"></app-breadcrumbs>

        <a *ngFor="let tag of itemTags" class="tags" [href]="'/search/.?q=' + tag" tabindex="-1"
           rel="noopener noreferrer nofollow">
          <span>{{tag}}</span>
        </a>
      </div>

      <!--/*********** TECHNICAL DESCRIPTION ***********/-->
      <div class="row-item row-border-top" *ngIf="item.techDescription">
        <mat-expansion-panel class="details-container mb-3">
          <mat-expansion-panel-header>
            <mat-panel-title>Technical details</mat-panel-title>
            <mat-panel-description></mat-panel-description>
          </mat-expansion-panel-header>
          <p [innerHTML]="('general.lang' | translate) == 'it' ? item.techDescription : item.techDescriptionEn"></p>
        </mat-expansion-panel>
      </div>

      <!--/*********** RELATED ***********/-->
      <div class="row-item row-border-top mobile-related">
        <h3 class="mini-titles">{{ 'models.relatedTitle' | translate }}</h3>
        <ul class="related-container text-center">
          <li *ngFor="let elem of itemRelated | slice:0:3; trackBy: trackById" class="related">
            <a [routerLink]="'/gallery-models/asset-3d/' + elem.name + '/.'" tabindex="-1"
                    [title]="('general.lang' | translate) == 'it' ? elem.title : elem.titleEn">
              <!--              <app-loading [classes]="'mini'"></app-loading>-->
              <img draggable="false" loading="lazy" [src]="PUBLIC_MODELS_IMAGES_3D + elem.name + '.min.jpg'"
                   width="100%" height="100%" alt="{{elem.name}}"/>
              <span class="related-title pb-1" [innerHTML]="('general.lang' | translate) == 'it' ? elem.title : elem.titleEn"></span>
            </a>
          </li>
        </ul>
        <!--
        <a id="preview-pre" *ngIf="itemPrev" href="javascript:void(0);"
           (click)="loadingPrev = true; changeItem(itemPrev.name)">
          <img draggable="false" [src]="PUBLIC_MODELS_IMAGES_3D + itemPrev.name + GALLERY_MIN_FILE_EXT" [alt]="itemPrev.name"
               width="60px" height="60px" />
          <i class="fa fa-chevron-left prev"></i>
        </a>

        <a id="preview-post" *ngIf="itemNext" href="javascript:void(0);"
           (click)="loadingNext = true; changeItem(itemNext.name)">
          <img draggable="false" [src]="PUBLIC_MODELS_IMAGES_3D + itemNext.name + GALLERY_MIN_FILE_EXT" [alt]="itemNext.name"
               width="60px" height="60px" />
          <i class="fa fa-chevron-right next"></i>
        </a>-->
      </div>

      <!--/*********** COMMENTS ***********/-->
      <div class="row-item row-border-top">
        <div *ngIf="itemComments">
          <app-comments [comments]="itemComments" [argument]="item.name" [status]="false"></app-comments>
        </div>
      </div>
    </div>
  </div>
<!--</app-modal>-->
</div>

<!--/*********** RIGHT SHOULDER ***********/-->
<div id="right-shoulder" class="def_back">
  <div id="container-item-border"></div>
  <div id="container-item-bottom"></div>
  <div *ngIf="!item && !galleryError && !galleryNotFound" class="lds-css">
    <app-loading></app-loading>
  </div>
  <ng-container *ngIf="item">
    <div class="row">
      <div class="col-12 text-center g-0">
        <h3 class="mini-titles">{{ 'models.infosTitle' | translate }}</h3>
        <!--  *ngIf="item.collection" -->
        <span class="info-links type-icon mb-2 {{item.type}}">
          <i class="fas fa-{{GALLERY_MODELS_TYPES[item.type]}}" aria-hidden="true"></i>
          {{'menu.sez3dModels' + (item.type | titlecase) | translate }}</span>
        <span class="info-links collection-icon mb-2" triggers="click"
              placement="left" [autoClose]="'outside'" ngbTooltip="{{'models.collection' | translate}}"
              *ngIf="item?.collection">Collection</span>
        <span class="info-links collection-icon mb-2" *ngIf="item?.collection && item?.count">
          {{item.count}} models
        </span>
        <span class="info-links lods-icon mb-2" *ngIf="item?.lods"
              triggers="click" placement="left" [autoClose]="'outside'"
              ngbTooltip="{{ 'models.lodsTooltip' | translate }}">
            {{ 'models.lods' | translate }} <i class="far fa-circle-question" aria-hidden="true"></i></span>
        <div *ngIf="item?.polygons && item?.collection">
          <span class="info-links vertices-icon mb-2" triggers="click" placement="left"
                [autoClose]="'outside'" ngbTooltip="{{ 'models.polygonsCollectionTooltip' | translate }}">
            <i class="fas fa-draw-polygon"></i> {{item.polygons}}&#126;
            <span [innerHTML]="'models.polygons' | translate"></span></span>
          <span class="info-links vertices-icon mb-2" triggers="click" placement="left"
                *ngIf="item.vertices"
                [autoClose]="'outside'" ngbTooltip="{{ 'models.verticesCollectionTooltip' | translate }}">
            <i class="fas fa-ellipsis-vertical"></i><i class="fas fa-ellipsis-vertical"></i>
            {{item.vertices}}&#126;
            <span [innerHTML]="'models.vertices' | translate"></span></span>
        </div>
        <div *ngIf="item?.polygons && !item?.collection">
          <span class="info-links vertices-icon mb-2" triggers="click" placement="left"
                *ngIf="item.polygons"
                [autoClose]="'outside'" ngbTooltip="{{ 'models.polygonsTooltip' | translate }}">
            <i class="fas fa-draw-polygon"></i> {{item.polygons}}
            <span [innerHTML]="'models.polygons' | translate"></span></span>
          <span class="info-links vertices-icon mb-2" triggers="click" placement="left"
                *ngIf="item.vertices"
                [autoClose]="'outside'" ngbTooltip="{{ 'models.verticesTooltip' | translate }}">
            <i class="fas fa-ellipsis-vertical"></i><i class="fas fa-ellipsis-vertical"></i>
            {{item.vertices}}
            <span [innerHTML]="'models.vertices' | translate"></span></span>
        </div>
      </div>
    </div>
    <div class="row-item row-border-top mt-3 pt-3 mb-3 text-center">
  <!--      <h3 class="mini-titles">{{ 'models.attributesTitle' | translate }}</h3>-->
      <button type="button" (click)="sendLike(item.name)" [class.disabled]="isDisabledLike" tabindex="-1"
              class="info-links like-link mb-2" triggers="hover" placement="left" [autoClose]="'outside'"
              ngbTooltip="{{'general.like' | translate}}">
          <span class="fa-heart" [ngClass]="isDisabledLike ? 'fas' : 'far'"
                [class.clicked]="isClickedLike"></span>
        <span class="like-count">{{ item?.likes }}</span>
      </button>
      <a class="info-links download-link mb-2" [href]="item?.downloadLinks" *ngIf="item?.downloadLinks" target="_blank"
         triggers="hover" placement="left" [autoClose]="'outside'"
         ngbTooltip="{{'models.linkNew' | translate}}">
        <i class="fas fa-download"></i>
        <span class="download-text">{{'general.download' | translate}}</span>
        <!--            <div class="get-link" [innerHTML]="'models.link' | translate: {link: item.downloadLinks}">-->
      </a>
      <button type="button" class="info-links share-link" (click)="onShare()" [class.shared]="isShared"
              triggers="hover" placement="left" [autoClose]="'outside'"
              ngbTooltip="{{'general.share' | translate}}">
        <i class="fas" [ngClass]="isShared ? 'fa-check' : 'fa-share'"></i>
        <span class="share-text" *ngIf="!isShared">{{'general.share' | translate}}</span>
        <span class="share-text shared-text" *ngIf="isShared">{{'general.shared' | translate}}</span>
        <!--            <div class="get-link" [innerHTML]="'models.link' | translate: {link: item.downloadLinks}">-->
      </button>
    </div>
    <div class="row-item row-border-top mt-3 pt-3 mb-3">
    <h3 class="mini-titles">{{ 'models.relatedTitle' | translate }}</h3>
    <ul class="related-container text-center">
      <li *ngFor="let elem of itemRelated | slice:0:4; trackBy: trackById" class="related">
        <a [routerLink]="'/gallery-models/asset-3d/' + elem.name + '/.'" tabindex="-1"
                [title]="('general.lang' | translate) == 'it' ? elem.title : elem.titleEn">
          <picture>
            <source [srcset]="PUBLIC_MODELS_IMAGES_3D + 'webp/' + elem.name + '.min' + GALLERY_FILE_EXT_NEW"  type="image/webp">
            <img draggable="false" [src]="PUBLIC_MODELS_IMAGES_3D + elem.name + '.min.jpg'"
                 [alt]="elem.titleEn" width="100%" height="100%" loading="lazy">
          </picture>
          <span class="related-title pb-1" [innerHTML]="('general.lang' | translate) == 'it' ? elem.title : elem.titleEn"></span>
        </a>
      </li>
    </ul>
  </div>
  </ng-container>
</div>
</div>

import {Model} from './Model';

export class Models {

  constructor(
    public pag: number,
    public pags: number,
    public items: Model[],
    public count: number,
  ) {}

  public static adapt(item: any): Models {
    return new Models(
      item?.pag,
      item?.pags,
      item?.items ? item.items.map(Model.adapt) : null,
      item?.count,
    );
  }
}

export class Slider {

  constructor(
    public id: number,
    public name: string,
    public src: string,
    public title: string,
  ) {}

  public static adapt(item): Slider {
    return new Slider(
      item?.id,
      item?.name,
      item?.src,
      item?.title
    );
  }
}

export const environment = {
  production: true,
  HOST_SERVICES: 'https://www.obyte.it/v1/rest/',
  // HOST_SERVICES: 'http://localhost:9999/api/v3/',
  // HOST_SERVICES: 'https://sandbay-be.eu-central-1.elasticbeanstalk.com/api/v3/',
  RECAPTCHA_PUBLIC_KEY: '6LfyhwgkAAAAAMQlZYTKdCe_3bA30o-I3MxskVOI',
  HOST_SEO: 'https://www.sandbay.it/',
  LOG_LEVEL: 'INFO',
  DEBUG: false,
  OAUTH_GOOGLE: {
    issuer: 'https://accounts.google.com',
    strictDiscoveryDocumentValidation: false,
    redirectUri: 'https://www.sandbay.it/user/auth/', // 'http://localhost:4605/user/login/', // window.location.origin,
    clientId: '222945314856-kl010qcnbm0nlgnf77qmtuil6egkn53i.apps.googleusercontent.com',
    scope: 'openid profile email',
  },
};

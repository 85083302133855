import {Tool} from './Tool';

export class ToolsMap {
  constructor(
    public tools: Tool[],
    public games: Tool[],
    public beta: Tool[]
  ) {}

  public static adapt(item: any): ToolsMap {
    return new ToolsMap(
      item.tools ? item.tools.map(Tool.adapt) : null,
      item.games ? item.games.map(Tool.adapt) : null,
      item.beta ? item.beta.map(Tool.adapt) : null,
    );
  }
}

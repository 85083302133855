import {Inject, Injectable} from '@angular/core';
import {ErrorEnum} from '@/users/models/ErrorEnum';
import {DOCUMENT} from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  private link: HTMLLinkElement;

  constructor(
    @Inject(DOCUMENT) private doc) {}

  /**
   * Error handling.
   */
  public static handleError(err): string[] {
    let messages = [];
    if (err.error !== null) {
      const typedErrorString: keyof typeof ErrorEnum = err.error;
      if (!ErrorEnum[typedErrorString]) {
        messages.push(ErrorEnum.UNKNOWN);
      } else {
        messages.push(ErrorEnum[typedErrorString]);
      }
    } else if (err.errors !== null) {
      messages = err.errors;
    } else {
      messages.push(ErrorEnum.UNKNOWN);
    }
    return messages;
  }

  public static handleGalleryError(err): boolean {
    console.error(err);
    return true;
  }

  /**
   * Lock scroll of the view.
   */
  public static lockScroll(): void {
    if (typeof document !== 'undefined') {
      document.body.style.overflow = 'hidden';
      document.querySelector( '.fadeIn' ).classList.add('blur');
      document.querySelector( '#container' ).classList.add('blur');
      document.querySelector( '#background' ).classList.add('blur');
      // Modal
      document.querySelector( '#container-modal' ).classList.add('modal');
    }
  }

  /**
   * Unlock scroll of the view.
   */
  public static unlockScroll(): void {
    if (typeof document !== 'undefined') {
      document.body.style.overflow = 'initial';
      document.querySelector( '.fadeIn' ).classList.remove('blur');
      document.querySelector( '#container' ).classList.remove('blur');
      document.querySelector( '#background' ).classList.remove('blur');
      // Modal
      document.querySelector( '#container-modal' ).classList.remove('modal');
    }
  }

  /**
   * Smooth scroll.
   * @param $element [Element to scroll to]
   */
  public static scroll($element): void {
    $element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
  }

  /**
   * Title case for angular.
   * @param value [string to process]
   */
  public static toTitleCase(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }

  /**
   * To generate an empty array.
   * @param i [size of the array]
   */
  public static counter(i: number) {
    return new Array(i);
  }

  /**
   * Lock landscape orientation mode and fullscreen mode.
   */
  public static openFullscreen(elem, mobile = false) {
    if (mobile && typeof screen !== 'undefined' && screen.orientation && screen.orientation.lock) {
      screen.orientation.lock('landscape');
    } // else if (!mobile) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  }

  /**
   * Unlock landscape orientation mode and close fullscreen mode.
   */
  public static closeFullscreen(elem, mobile = false) {
    if (mobile && typeof screen !== 'undefined' && screen.orientation && screen.orientation.unlock) {
      screen.orientation.unlock();
    } // else if (!mobile) {
    if (elem.exitFullscreen) {
      elem.exitFullscreen();
    } else if (elem.mozCancelFullScreen) {
      /* Firefox */
      elem.mozCancelFullScreen();
    } else if (elem.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      elem.webkitExitFullscreen();
    } else if (elem.msExitFullscreen) {
      /* IE/Edge */
      elem.msExitFullscreen();
    }
  }

  /**
   * Create head meta tag for canonical page url.
   */
  createLinkForCanonicalURL(url: string = null) {
    if (this.link === undefined) {
      this.link = this.doc.createElement('link');
      this.link.setAttribute('rel', 'canonical');
      this.doc.head.appendChild(this.link);
    }
    this.link.setAttribute('href', url ? url : this.doc.URL.split('?')[0]);
  }

  /*
   * @deprecated
   * @type {[any , any , any]}
   *
  static fadeInAndOut: any[] = [
    state('in', style({ transform: 'scale3d(.0, .0, .0)' })),
    transition('void => *', [
      style({ transform: 'scale3d(.3, .3, .3)' }),
      animate(100)
    ]),
    transition('* => void', [
      animate(100, style({ transform: 'scale3d(.0, .0, .0)' }))
    ])
  ];
   */

  /*
   * @deprecated
   * @type {[any , any]}
   *
  static fadeIn: any[] = [
    state('in', style({ transform: 'scale3d(.0, .0, .0)' })),
    transition('void => *', [
      style({ transform: 'scale3d(.3, .3, .3)' }),
      animate(150)
    ])
  ];
   */
}

import {Role} from './Role';
import {Background} from './Background';

export class User {
  constructor(
    // public id: number,
    public username: string,
    public email: string,
    public likes: string,
    public role?: Role,
    public background?: Background,
    public token?: string,
    public webSite?: string,
    public sub?: string,
    public picture?: string,
    public locale?: string,
  ) {}

  public toString() {
    const SEPARATOR = ' | ';
    return 'A user named: ' + this.username
      + SEPARATOR + this.email
      + SEPARATOR + this.likes
      + SEPARATOR + this.webSite
      + SEPARATOR + this.role
      + SEPARATOR + this.background
      + SEPARATOR + this.token + '|';
  }
}

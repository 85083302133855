<div id="backgrounds-toggle" (click)="backsOpen = !backsOpen" class="toggles"
     (clickOutside)="backsOpen = false" [class.open]="backsOpen">
  <i class="fas fa-15x fa-fill-drip"></i>
  <ul id="backs-toggle-nl" class="backs-toggle">
    <li class="backs-toggle-item" (click)="switchTheme('standard')">
      <span id="backs-toggle-icon-standard" class="backs-toggle-icon fas fa-certificate"></span>
    </li>
    <li class="backs-toggle-item" (click)="switchTheme('space')">
      <span id="backs-toggle-icon-space" class="backs-toggle-icon fas fa-rocket"></span>
    </li>
    <li class="backs-toggle-item" (click)="switchTheme('sea')">
      <span id="backs-toggle-icon-sea" class="backs-toggle-icon fas fa-fish"></span>
    </li>
    <li class="backs-toggle-item" (click)="switchTheme('snow')">
      <span id="backs-toggle-icon-snow" class="backs-toggle-icon fas fa-snowflake"></span>
    </li>
    <li class="backs-toggle-item" (click)="switchTheme('nature')">
      <span id="backs-toggle-icon-nature" class="backs-toggle-icon fas fa-tree"></span>
    </li>
    <!--<li class="backs-toggle-item" (click)="switchTheme('desert')">
      <span id="backs-toggle-icon-d" class="backs-toggle-icon sprite_world"></span>
    </li>-->
    <!--<li class="backs-toggle-item" (click)="switchTheme('exa')">
      <span id="backs-toggle-icon-e" class="backs-toggle-icon sprite_world"></span>
    </li>-->
  </ul>
</div>

<div (clickOutside)="searchOpen = false">
  <span id="search-toggle" tabindex="0" title="Search" class="toggles"
     (click)="searchOpen = !searchOpen" *ngIf="!isSearchUrl()" [class.open]="searchOpen">
    <i class="fas fa-15x fa-search"></i>
  </span>
  <div id="search-container" class="container-lg" [class.open]="searchOpen" *ngIf="searchOpen && !isSearchUrl()">
    <app-search></app-search>
  </div>
</div>

<div>
  <span *ngIf="!userName" id="auth-toggle" class="toggles"
        routerLink="/user/login/." tabindex="0" title="Search">
    <i class="fas fa-15x fa-key"></i>
  </span>
</div>

export class Model {

  constructor(
    public id?: number,
    public name?: string,
    public title?: string,
    public titleEn?: string,
    public type?: string,
    public mNum?: number,
    public size = 300, // Height.
    public width?: number,
    public added?: string,
    public tags?: string[],
    public likes?: number,
    public downloadLinks?: string,
    public collection?: boolean,
    public count?: number,
    public lods?: boolean,
    public description?: string,
    public techDescription?: string,
    public descriptionEn?: string,
    public techDescriptionEn?: string,
    public vertices?: number,
    public polygons?: number) {}

  public static adapt(item: any): Model | null {
    return item ? new Model(
      item.id,
      item.name,
      item.title,
      item.title_en || item.titleEn,
      item.type,
      item.m_num || item.mNum,
      item.size,
      item.width,
      item.added,
      item.tags,
      item.likes,
      item.download_links || item.downloadLinks,
      !!item.collection,
      item.count,
      !!item.lods,
      item.description,
      item.tech_description || item.techDescription,
      item.description_en || item.descriptionEn,
      item.tech_description_en || item.techDescriptionEn,
      item.vertices,
      item.polygons,
    ) : null;
  }
}

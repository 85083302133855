import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {GallerySearchComponent} from './components/gallery-search/gallery-search.component';
import {GalleryModelsComponent} from './components/gallery-models/gallery-models.component';
import {GalleryModelsItemComponent} from './components/gallery-models/item/gallery-models-item.component';
import {GalleryGraphicsComponent} from './components/gallery-graphics/gallery-graphics.component';
import {GalleryGraphicsItemComponent} from './components/gallery-graphics/item/gallery-graphics-item.component';
import {GalleryToolsComponent} from './components/gallery-tools/gallery-tools.component';
import {GalleryToolsItemComponent} from './components/gallery-tools/item/gallery-tools-item.component';
import {GalleryWebsitesComponent} from './components/gallery-websites/gallery-websites.component';
import {PATHS} from '@/Constants';
import {GalleryGraphicsCategoryComponent} from '@galleries/components/gallery-graphics/category/gallery-graphics-category.component';


const routes: Routes = [
  // { path: 'gallery-models/objects/2/.', component: GalleryModelsComponent },
  { path: 'search/.', component: GallerySearchComponent },
  { path: 'gallery-models/.', component: GalleryModelsComponent },
  { path: 'gallery-models/page/:page/.', component: GalleryModelsComponent},
  { path: 'gallery-models/:category/.', component: GalleryModelsComponent },
  { path: 'gallery-models/:category/page/:page/.', component: GalleryModelsComponent},
  // Data requires a different activated route.
  // { path: 'gallery-models/buildings/.', component: GalleryModelsComponent, data: {category: 'buildings'}},
  // { path: 'gallery-models/weapons/.', component: GalleryModelsComponent, data: {category: 'weapons'} },
  // { path: 'gallery-models/objects/.', component: GalleryModelsComponent, data: {category: 'objects'} },
  // { path: 'gallery-models/animals/.', component: GalleryModelsComponent, data: {category: 'animals'} },
  // { path: 'gallery-models/characters/.', component: GalleryModelsComponent, data: {category: 'characters'} },
  // { path: 'gallery-models/monsters/.', component: GalleryModelsComponent, data: {category: 'monsters'} },
  // { path: 'gallery-models/vehicles/.', component: GalleryModelsComponent, data: {category: 'vehicles'} },
  // { path: 'gallery-models/foods/.', component: GalleryModelsComponent, data: {category: 'foods'} },
  // { path: 'gallery-models/:page/.', component: GalleryModelsComponent },
  { path: 'gallery-models/asset-3d/:name/.', component: GalleryModelsItemComponent },
  { path: 'gallery-models/asset-3d/:name/:number/.', component: GalleryModelsItemComponent },
  { path: 'gallery-models/:category/asset-3d/:name/:number/.', component: GalleryModelsItemComponent,
    redirectTo: 'gallery-models/asset-3d/:name/:number/.' },
  // { path: '3d/:name', component: GalleryModelsItemComponent, outlet: 'asset' },
  // { path: '3d/:name/:number', component: GalleryModelsItemComponent, outlet: 'asset' },
  { path: 'gallery-graphics/.', component: GalleryGraphicsComponent },
  { path: 'gallery-graphics/renders/.', component: GalleryGraphicsCategoryComponent, data: {category: 'renders'} },
  { path: 'gallery-graphics/videos/.', component: GalleryGraphicsCategoryComponent, data: {category: 'videos'} },
  { path: 'gallery-graphics/others/.', component: GalleryGraphicsCategoryComponent, data: {category: 'others'} },
  { path: 'graphic/:name', component: GalleryGraphicsItemComponent, outlet: 'asset' },
  { path: 'gallery-tools/.', component: GalleryToolsComponent },
  { path: 'tool/:name', component: GalleryToolsItemComponent, outlet: 'asset' },
  { path: 'gallery-websites/.', component: GalleryWebsitesComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GalleriesRoutingModule { }

import {Graphic} from './Graphic';

export class GraphicsMap {
  constructor(
    public renders: Graphic[],
    public videos: Graphic[],
    public others: Graphic[],
  ) {}

  public static adapt(item: any): GraphicsMap {
    return new GraphicsMap(
      item?.render ? item.render.map(Graphic.adapt) : null,
      item?.video ? item.video.map(Graphic.adapt) : null,
      item?.other ? item.other.map(Graphic.adapt) : null,
    );
  }
}

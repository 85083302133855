import {EventEmitter, Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AUTH_PROTECTED_METHODS} from '@/Constants';
import {Observable, Subject} from 'rxjs';
import {User} from '@users/models/User';
import {Role} from '@users/models/Role';
import {Background} from '@users/models/Background';
import * as jwt_decode from 'jwt-decode';
import {ErrorEnum} from '@/users/models/ErrorEnum';
import {environment} from '~/environments/environment';
import {StorageService} from '@/services/storage.service';
import {Logger} from '@/services/logger.service';
import {AuthConfig, OAuthService} from 'angular-oauth2-oidc';

export interface UserInfo {
  sub: string;
  email: string;
  name: string;
  username: string;
  picture: string;
}

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  public static oAuthConfig: AuthConfig = environment.OAUTH_GOOGLE;
  // private userProfileSubject = new Subject<UserInfo>();

  private api: string;
  authenticated = new EventEmitter<boolean>();

  constructor(private _jwtHelper: JwtHelperService,
              private readonly _http: HttpClient,
              private readonly oAuthService: OAuthService) {
    this.api = `${environment.HOST_SERVICES}`; // user/
  }

  public static getToken(): string {
    return StorageService.get('token');
  }

  private static getDecodedToken(): any {
    try {
      return jwt_decode(UsersService.getToken());
    } catch (Error) {
      return null;
    }
  }

  public static setToken(token: string) {
    StorageService.set('token', token);
  }

  public static setUser() {
    const decodedToken = UsersService.getDecodedToken();
    const typedBackgroundString: keyof typeof Background = decodedToken.data?.background;
    const user = new User(
      decodedToken.data.username,
      decodedToken.data.email,
      decodedToken.data.likes,
      Role.USER,
      Background[typedBackgroundString],
      null,
      decodedToken.data.webSite,
      decodedToken.data?.sub || null,
      decodedToken.data?.picture || null,
      decodedToken.data?.locale || null,
    );
    // Set user likes.
    UsersService.setLikes(decodedToken.data?.likes?.split(',') || []);
    // console.log(user.toString());
    StorageService.set('user', JSON.stringify(user));
  }

  public static getUser(): User {
    const decodedToken = UsersService.getDecodedToken();
    if (decodedToken != null) {
      const typedRoleString: keyof typeof Role = decodedToken.data.role;
      const typedBackgroundString: keyof typeof Background = decodedToken.data.background;
      return new User(
        decodedToken.data.username,
        decodedToken.data.email,
        decodedToken.data.likes,
        Role[typedRoleString],
        Background[typedBackgroundString],
        null,
        decodedToken.data?.webSite || null,
        decodedToken.data?.sub || null,
        decodedToken.data?.picture || null,
        decodedToken.data?.locale || null,
      );
    }
    return null;
  }

  public static isProtected(fullUrl: string) {
    // const lastUrl = fullUrl.substring(fullUrl.lastIndexOf('api/v3/') + 7);
    const lastUrl = fullUrl.substring(fullUrl.lastIndexOf('/') + 1);
    return (AUTH_PROTECTED_METHODS.find(x => x === lastUrl) !== undefined);
    // console.log('isProtected?', isProtected, lastUrl, AUTH_PROTECTED_METHODS);
    // return isProtected;
  }

  public static getUsername(): string {
    const userJson = StorageService.get('user');
    return (userJson != null) ? JSON.parse(userJson).username : null;
  }

  public static getOnlyName(): string {
    const username = UsersService.getUsername();
    return typeof username !== 'undefined' ? username.split(' ')[0] : '';
  }

  public static getEmail(): string {
    const userJson = StorageService.get('user');
    return (userJson != null) ? JSON.parse(userJson).email : null;
  }

  public static setLang(lang: string) {
    StorageService.set('lang', lang);
  }

  public static getLang(): string {
    return StorageService.get('lang') || '';
  }

  /**
   * Set user likes.
   */
  public static setLikes(likes: string[]) {
    const storageLikes = StorageService.get('likes');
    const userLikes = UsersService.getUser()?.likes;
    let totalLikes = likes
      .concat(userLikes?.split(',') || [])
      .concat(storageLikes?.split(',') || []);
    totalLikes = totalLikes.map(o => o.replace(/[^0-9a-z_-]/gi, ''));
    const totalLikesToStore = totalLikes.filter(
      (value, index, array) => array.indexOf(value) === index
    ).join(',');
    StorageService.set('likes', totalLikesToStore);
  }

  /**
   * Get use likes.
   */
  public static getLikes(): string[] {
    let likes = StorageService.get('likes');
    if (likes) {
      likes = likes.replace(/[^0-9a-z,_-]/gi, '');
      return likes.split(',').filter(x => x); // Remove empty values. Cast isn't needed.
    }
    return [];
  }

  public static handleValidationError(res): string[] {
    let messages = [];
    if (res.result != null && res.result.message != null) {
      const typedErrorString: keyof typeof ErrorEnum = res.result.message;
      messages.push(ErrorEnum[typedErrorString]);
    } else if (res.messages != null) {
      messages = res.messages;
    } else {
      messages.push(ErrorEnum.UNKNOWN);
    }
    return messages;
  }

  public static isAdmin() {
    const user = UsersService.getUser();
    return (user != null) ? (user.role === Role.ADMIN) : false;
  }

  public login(username: string, password: string): Observable<any> {
    const params = new HttpParams()
      .append('username', username)
      .append('password', password);
    return this._http.post(`${this.api}login`, params); // auth/
  }

  public loginFromIssuer(username: string, email: string,
                         sub?: string, picture?: string, locale?: string): Observable<any> {
    const params = new HttpParams()
      .append('username', username)
      .append('email', email)
      .append('sub', sub)
      .append('picture', picture)
      .append('locale', locale);
    Logger.debug('Login from issuer: ', params);
    return this._http.post(`${this.api}login`, params); // auth/google/
  }

  public register(username: string, password: string,
                  password2: string, email: string,
                  website: string, conditions: string, recaptcha: string,
                  sub?: string, picture?: string, locale?: string): Observable<any> {
    let params = new HttpParams()
      .append('username', username)
      .append('password', password)
      .append('password2', password2)
      .append('email', email)
      .append('conditions', conditions)
      .append('recaptcha', recaptcha);
    if (sub) {
      params = params
        .append('sub', sub)
        .append('picture', picture)
        .append('locale', locale);
    }
    if (website) {
      params = params.append('website', website);
    }
    Logger.debug('Register service: ', params);
    return this._http.post(`${this.api}registration`, params); // register/
  }

  public passwordModify(password: string, password2: string, captcha: string): Observable<any> {
    const params = new HttpParams()
      .append('password', password)
      .append('password2', password2)
      .append('recaptcha', captcha);
    return this._http.post(`${this.api}passwordModify`, params); // pass/change/
  }

  public passwordRecoveryStep1(email: string, username = null, captcha: string): Observable<any> {
    const params = new HttpParams()
      .append('email', email)
      .append('username', username)
      .append('recaptcha', captcha);
    return this._http.post(`${this.api}passwordRecoveryStep1`, params); // pass/recover-1/
  }

  public passwordRecoveryStep2(key: string, password: string, password2: string, email: string): Observable<any> {
    const params = new HttpParams()
      .append('key', key)
      .append('password', password)
      .append('password2', password2)
      .append('email', email);
    return this._http.post(`${this.api}passwordRecoveryStep2`, params); // pass/recover-2/
  }

  public getProfile(): Observable<any> {
    return this._http.get(`${this.api}getProfile`); // profile/
  }

  public setWebSite(webSite: string): Observable<any> {
    const params = new HttpParams()
      .append('website', webSite);
    return this._http.post(`${this.api}addWebSite`, params); // website/
  }

  public isAuthenticated(): boolean {
    if (UsersService.getToken()) {
      return !this._jwtHelper.isTokenExpired(UsersService.getToken());
    }
    return false;
  }

  public logout() {
    StorageService.remove('token');
    StorageService.remove('user');
    this.authenticated.emit(false);
    this.oAuthService.logOut();
  }

  public lightRegister(params: { sub: string, username: string, email: string, picture?: string, locale?: string}) {
    // AUTO LOGIN
    this.loginFromIssuer(params.username, params.email, params.sub, params.picture, params.locale).subscribe({
      next: login => {
        if (login.result != null && login.code === 0) {
          UsersService.setToken(login.result.jwt);
          UsersService.setUser();
          this.authenticated.emit(true);
        }
      }, error: err => {}
    });
  }
}

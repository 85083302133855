<div id="container" class="fadeIn home pt-2">
  <h1 class="sr-only">SandBay Homepage</h1>
  <div id="slider">
    <div id="slider-content">
      <div class="loading-background">
        <app-loading></app-loading>
        <picture>
          <source media="(max-width: 768px)" srcset="https://tools.obyte.it/img/idrobyte/pirateTable2_768.webp" type="image/webp">
          <source media="(max-width: 425px)" srcset="https://tools.obyte.it/img/idrobyte/pirateTable2_425.webp" type="image/webp">
          <source media="(max-width: 320px)" srcset="https://tools.obyte.it/img/idrobyte/pirateTable2_320.webp" type="image/webp">
          <source srcset="https://tools.obyte.it/img/idrobyte/pirateTable2_912.webp" type="image/webp">
          <img draggable="false" src="https://tools.obyte.it/img/idrobyte/pirateTable2_912.jpg" alt="Home page pirate table image">
        </picture>
      </div>
      <!--<ngb-carousel *ngIf="items" class="carousel-fade">
        <ng-template ngbSlide *ngFor="let item of items; trackBy: trackById">
          <div class="loading-background">
            <app-loading></app-loading>
            <picture>
              <source media="(max-width: 768px)" [srcset]="PUBLIC_SLIDERS_IMAGES_SET + 'webp/' + item.src + '_768.webp'" type="image/webp">
              <source media="(max-width: 425px)" [srcset]="PUBLIC_SLIDERS_IMAGES_SET + 'webp/' + item.src + '_425.webp'" type="image/webp">
              <source media="(max-width: 320px)" [srcset]="PUBLIC_SLIDERS_IMAGES_SET + 'webp/' + item.src + '_320.webp'" type="image/webp">
              <source [srcset]="PUBLIC_SLIDERS_IMAGES + 'webp/' + item.src + '.webp'" type="image/webp">
              <img draggable="false" [src]="PUBLIC_SLIDERS_IMAGES + item.src + '.jpg'" [alt]="item.src">
            </picture>
          </div>
          <div *ngIf="item.name" class="carousel-caption">
            <h3>{{('index.' + item.name) | translate }}</h3>
          </div>
        </ng-template>
      </ngb-carousel>-->
    </div>
  </div>

  <div id="home-list">
    <!--<li class="vertical-line"></li>-->
    <div class="home-img">
      <div class="home-img-content {{sections[0].img}}"><div></div></div>
      <div class="home-img-content {{sections[1].img}}"><div></div></div>
      <div class="home-img-content {{sections[2].img}}"><div></div></div>
      <div class="home-img-content {{sections[3].img}}"><div></div></div>
    </div>
    <div class="home-wrapper">
      <div app-home-exa [section]="sections[0]" [i]="0"></div>
      <div class="home-center-sections">
        <div app-home-exa [section]="sections[1]" [i]="1"></div>
        <div app-home-exa [section]="sections[2]" [i]="2"></div>
      </div>
      <div app-home-exa [section]="sections[3]" [i]="3"></div>
    </div>
  </div>
</div>

import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {Logger} from '@/services/logger.service';
import {PATHS} from '@/Constants';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./_error.component.scss']
})
export class ErrorComponent implements OnInit, OnDestroy {
  code = '404';
  errorTitle = 'Page Not Found';
  errorDesc = 'Sorry, but the page you were trying to view does not exist.';
  private _paramsSubscription: Subscription;
  path: string;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router) { }

  ngOnInit() {
    this._paramsSubscription = this._activatedRoute.params.subscribe((params: Params) => {
      if (typeof params.code !== 'undefined' && params.code.length === 3) {
        switch (params.code) {
          case 'teapot':
          case '418':
            this.code = '418';
            this.errorTitle = 'I\'m a teapot';
            this.errorDesc = '';
            break;
          case '404':
            this.code = '404';
            break;
          case '403':
            this.code = '403';
            this.errorTitle = 'Access Denied';
            this.errorDesc = '';
            break;
          case '402':
          case '401':
          case '400':
            this.code = params.code;
            this.errorTitle = '';
            this.errorDesc = '';
            break;
        }
        Logger.log('Error ' + this.code);
      }
    });

    // smart 404
    this.path = this.resolve(this._router.url);
  }

  ngOnDestroy(): void {
    this._paramsSubscription.unsubscribe();
  }

  /**
   *
   * @param url 'Get the typo url and resolve it the nearest.'
   */
  private resolve(url: string): string | null {
    const typoPath = url.replace('/', '');
    const threshold = this.getThreshold(typoPath);
    const dictionary = Object.values(PATHS)
      .filter(path => Math.abs(path.length - typoPath.length) < threshold);

    if (!dictionary.length) {
      return null;
    }

    this.sortByDistances(typoPath, dictionary);

    return `/${dictionary[0]}`;
  }

  /**
   * Sorts by an algorithm.
   */
  private sortByDistances(typoPath: string, dictionary: string[]) {
    const pathsDistance = {} as { [name: string]: number };

    dictionary.sort((a, b) => {
      if (!(a in pathsDistance)) {
        pathsDistance[a] = this.levenshtein(a, typoPath);
      }
      if (!(b in pathsDistance)) {
        pathsDistance[b] = this.levenshtein(b, typoPath);
      }

      return pathsDistance[a] - pathsDistance[b];
    });
  }

  /**
   * Range of needed characters.
   */
  private getThreshold(path: string): number {
    if (path.length < 5) {
      return 3;
    }
    return 5;
  }

  /**
   * Levenshtein algorithm for 404 smart pages.
   */
  private levenshtein = (a: string, b: string): number => {
    if (a.length === 0) {
      return b.length;
    }
    if (b.length === 0) {
      return a.length;
    }

    const matrix = [];

    // increment along the first column of each row
    for (let i = 0; i <= b.length; i++) {
      matrix[i] = [i];
    }

    // increment each column in the first row
    for (let j = 0; j <= a.length; j++) {
      matrix[0][j] = j;
    }

    // Fill in the rest of the matrix
    for (let i = 1; i <= b.length; i++) {
      for (let j = 1; j <= a.length; j++) {
        if (b.charAt(i - 1) === a.charAt(j - 1)) {
          matrix[i][j] = matrix[i - 1][j - 1];
        } else {
          matrix[i][j] = Math.min(
            matrix[i - 1][j - 1] + 1, // substitution
            matrix[i][j - 1] + 1, // insertion
            matrix[i - 1][j] + 1, // deletion
          );
        }
      }
    }

    return matrix[b.length][a.length];
  }
}

import {Component, OnDestroy, OnInit, HostListener, Inject} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Meta, Title} from '@angular/platform-browser';
import {combineLatest, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

import {
  GALLERY_FILE_EXT, GALLERY_FILE_EXT_NEW, GALLERY_MODELS_DESCRIPTION, GALLERY_MODELS_TYPES,
  PUBLIC_MODELS_IMAGES_3D, TITLE_APPEND
} from '@/Constants';
import {Model} from '@/models/Model';
import {Comment} from '@/models/Comment';
import {GalleryService} from '@galleries/services/gallery.service';
import {UsersService} from '@/users/services/users.service';
import {UtilsService} from '@/services/utils.service';
import {Logger} from '@/services/logger.service';
import {Clipboard} from '@angular/cdk/clipboard';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-gallery-models-item',
  templateUrl: './gallery-models-item.component.html',
  styleUrls: ['./_gallery-models-item.component.scss']
})
export class GalleryModelsItemComponent implements OnInit, OnDestroy {
  PUBLIC_MODELS_IMAGES_3D = PUBLIC_MODELS_IMAGES_3D;
  // GALLERY_MIN_FILE_EXT = GALLERY_MIN_FILE_EXT;
  GALLERY_FILE_EXT = GALLERY_FILE_EXT;
  GALLERY_FILE_EXT_NEW = GALLERY_FILE_EXT_NEW;
  GALLERY_MODELS_TYPES = GALLERY_MODELS_TYPES;
  private GALLERY_MODELS_URL = 'https://www.sandbay.it/gallery-models/';

  item: Model;
  itemRelated: Model[];
  itemComments: Comment[];
  itemNumber = 1;
  itemsArray = [];
  itemTags: string[];
  itemDownloadCount: number;

  private _itemName: string;
  private subs: Subscription[] = [];

  loadingPrev = false;
  loadingNext = false;
  loadingUp = false;
  loadingDown = false;
  loadingDownload = false;
  galleryError: boolean;
  galleryNotFound: boolean;
  isDisabledLike: boolean;
  isClickedLike: boolean;
  isShared: boolean;
  elem: any;
  isFullScreen: boolean;
  showFullScreenButton: boolean;

  constructor(
    private _title: Title,
    private _meta: Meta,
    private _router: Router,
    private route: ActivatedRoute,
    private _utilsService: UtilsService,
    private _galleryService: GalleryService,
    private _translate: TranslateService,
    private clipboard: Clipboard,
    @Inject(DOCUMENT) private document: any) {}

  ngOnInit() {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
    this.subs.push(combineLatest([this.route.params, this.route.queryParams],
      (params, queryParams) => ({ params, queryParams })).subscribe(result => {
      this._itemName = result.params.name;
      const itemNumber = result.queryParams.n;
      this.itemNumber = itemNumber ? +itemNumber : 1;
      this.getItem(this._itemName, this.itemNumber);
      this.disableLike(this._itemName);
      this.checkScreenMode();
    }));
      // .pipe(map(results => ({params: results[0].name, queryParams: results[1].n})));
    // this.subs.push(this.route.params.subscribe((params: Params) => {
    //   this._itemName = params.name;
    //   const itemNumber = this.route.snapshot.queryParams.n;
    //
    //   this.itemNumber = itemNumber ? +itemNumber : 1;
    //   this.getItem(this._itemName, this.itemNumber);
    //   this.disableLike(this._itemName);
    //   this.checkScreenMode();
    //   // this.subs.push(this.route.queryParams.subscribe((queryParams: Params) => {
    //   //   this.itemNumber = queryParams.n ? +queryParams.n : 1;
    //   //   this.getItem(this._itemName, this.itemNumber);
    //   //   this.disableLike(this._itemName);
    //   //   this.checkScreenMode();
    //   // }));
    // }));
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  /**
   * Main method to get element.
   */
  getItem(itemName: string, itemNumber = 1) {
    this.subs.push(this._galleryService.getModelsItem(itemName, itemNumber)
      .subscribe({
        next: res => {
          if (res != null) {
            this.item = res.items;
            this.itemRelated = res.related;
            this.itemTags = this.item.tags;
            this.itemComments = res.comments;
            this.itemNumber = this.itemNumber > this.item.mNum ? this.item.mNum : itemNumber;
            this.itemsArray = Array.from(Array(+this.item.mNum), (x, i) => i + 1);
            this.itemDownloadCount = res.countDownload;
            const pageTitle = (this._translate.instant('general.lang') === 'it' ? this.item.title : this.item.titleEn);
            this._title.setTitle(`3D models - ${pageTitle}${TITLE_APPEND}`);
            this._meta.updateTag({property: 'og:title', content: `3D models - ${pageTitle}${TITLE_APPEND}`});
            // Description for search engines.
            let description = `3D model: ${pageTitle}, ready for 3D animation and other 3D projects.`;
            if (this.item.descriptionEn) {
              // description = (this._translate.instant('general.lang') === 'it' ? this.item.description : this.item.descriptionEn);
              let descriptionTemp = this.item.descriptionEn;
              descriptionTemp = descriptionTemp.substring(0, descriptionTemp.indexOf('.') + 1);
              description = (descriptionTemp.length < 88) ? (description + ' ' + descriptionTemp) : descriptionTemp;
            }
            this._meta.updateTag({ name: 'description', content: description });
            this._meta.updateTag({property: 'og:description', content: description});
            this._meta.updateTag({property: 'og:image', content: `https://tools.obyte.it/public/models/images/${itemName}.jpg`});
            this._meta.updateTag({property: 'og:url', content: `${this.GALLERY_MODELS_URL}asset-3d/${itemName}/?n=${itemNumber}`});
            this._utilsService.createLinkForCanonicalURL(`${this.GALLERY_MODELS_URL}asset-3d/${itemName}/?n=${itemNumber}`);
            this.loadingPrev = this.loadingNext = this.loadingUp = this.loadingDown = this.loadingDownload = false;
            Logger.debug('This item model:', this.item);
          } else {
            this.galleryNotFound = true;
          }
        },
        error: err => {
          this.galleryError = UtilsService.handleGalleryError(err);
        }
      }));
  }

  /**
   * Navigate to other related item.
   */
  changeItem(name: string, paramNumber = 1) {
    if (paramNumber === 1) {
      this.isShared = false;
    }
    if (name && paramNumber === 1) {
      void this._router.navigate([`/gallery-models/asset-3d/${name}/.`]);
      // void this._router.navigate([{outlets: {asset: ['3d', name, paramNumber]}}]);
    } else {
      void this._router.navigate([`/gallery-models/asset-3d/${name}/.`], {
        queryParams: {n: paramNumber}
      });
    }
  }

  /**
   * Download function.
   * @deprecated
   * saveItem(itemName: string): boolean {
   *   this.itemDownloadCount = +(this.itemDownloadCount) + 1;
   *   return this._galleryService.downloadFile(itemName);
   * }
   */

  /**
   * Send like to an item.
   */
  sendLike(itemName: string) {
    const likes = UsersService.getLikes() || [];
    if (likes.indexOf(itemName) < 0) {
      likes.push(itemName);
      UsersService.setLikes(likes);
      this.subs.push(this._galleryService.sendLike(itemName).subscribe(res => {
        if (res.code === 0) {
          this.item.likes = +(this.item?.likes) + 1;
          this.isDisabledLike = true;
          this.isClickedLike = true;
        } else {
          console.warn('Like failed:', res.code);
        }
      }));
    }
  }

  /**
   * Disable like when liked already.
   */
  disableLike(itemName: string): void {
    const likes = UsersService.getLikes() || '';
    this.isDisabledLike = !(likes.indexOf(itemName) === -1);
  }

  /**
   * Close model item modal (also remove fullscreen mode if active).
   */
  close() {
    if (this.isFullScreen) {
      this.closeFullscreen();
    }
    this._title.setTitle(`3D models${TITLE_APPEND}`);
    this._meta.updateTag({ name: 'description', content: GALLERY_MODELS_DESCRIPTION });
    this._meta.updateTag({property: 'og:title', content: `3D models${TITLE_APPEND}` });
    this._meta.updateTag({property: 'og:description', content: GALLERY_MODELS_DESCRIPTION });
    this._meta.updateTag({property: 'og:image', content: 'https://www.sandbay.it/assets/logo.png' });
    this._meta.updateTag({property: 'og:url', content: this.GALLERY_MODELS_URL });
    this._utilsService.createLinkForCanonicalURL(this.GALLERY_MODELS_URL );
    void this._router.navigate([{ outlets: { asset: null } }]);
    // this.router.navigateByUrl('/gallery-models');
  }

  /** @deprecated
   * Click on tag.
   *  search(tags: string) {
   *    this._router.navigateByUrl('/search/.?q=' + tags);
   *  }
   */

  /**
   * Click share button event.
   */
  onShare() {
    if (!this.isShared && this.item) {
      // this.clipboard.copy(window.location.href);
      this.clipboard.copy(`${this.GALLERY_MODELS_URL}asset-3d/${this.item.name}/`);
      this.isShared = true;
      setTimeout(() => { this.isShared = false; }, 5000);
    }
  }

  /**
   * trackBy for performance.
   */
  public trackById(index, item) {
    return item.title;
  }

  /**
   * Fullscreen.
   */
  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:webkitfullscreenchange', ['$event'])
  @HostListener('document:mozfullscreenchange', ['$event'])
  @HostListener('document:MSFullscreenChange', ['$event'])
  fullscreen_modes() { // (event) {
    this.checkScreenMode();
  }
  checkScreenMode() {
    if (typeof document !== 'undefined') {
      this.elem = document.documentElement;
      this.isFullScreen = !!document.fullscreenElement;

      if (typeof screen !== 'undefined' && screen.orientation && screen.orientation.lock) {
        this.showFullScreenButton = true;
      }
    }
  }
  openFullscreen(mobile = false) {
    UtilsService.openFullscreen(this.elem, mobile);
  }
  closeFullscreen(mobile = false) {
    UtilsService.closeFullscreen(this.document, mobile);
  }
}

<div class="c-loading" [class.mini]="classes === 'mini'" [class.big]="classes === 'big'">
  <div class="cube">
    <div class="front"> </div>
    <div class="back"> </div>
    <div class="right"> </div>
    <div class="left"> </div>
    <div class="top"> </div>
    <div class="bottom"> </div>
  </div>
</div>

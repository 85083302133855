
<p *ngIf="status" id="message-sent">{{labels?.messageSent}}</p>
<form *ngIf="!status" id="contacts-form" [formGroup]="contactFormGroup" (ngSubmit)="onSubmit()">
  <div class="row mt-4">
    <mat-form-field appearance="fill" class="col-12">
      <i class="input-icon fas fa-comment" matPrefix>&nbsp;</i>
      <textarea matInput id="message" #message [placeholder]="labels?.commentLabel" name="message"
                matTextareaAutosize matAutosizeMinRows="4" matAutosizeMaxRows="10"
                maxlength="1000" formControlName="message" autocomplete="off" aria-label="Contact message"
                [errorStateMatcher]="matcher" required></textarea>
      <mat-hint align="start">{{labels?.commentLimit}}</mat-hint>
      <mat-hint align="end">{{message.value.length}} / {{MAX_MESSAGE_LENGTH}}</mat-hint>
      <mat-error *ngIf="contactFormGroup.get('message').hasError('required')">
          {{labels?.commentRequired}}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field appearance="fill" class="mt-4 col-md-6 col-sm-12">
      <i class="input-icon fas fa-user" matPrefix>&nbsp;</i>
      <input matInput [placeholder]="labels?.nameLabel" name="username" formControlName="username"
             [errorStateMatcher]="matcher" required autocomplete="off" aria-label="User name">
      <mat-error *ngIf="username.errors?.required">{{labels?.nameRequired}}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill" class="mt-4 col-md-6 col-sm-12">
      <i class="input-icon far fa-envelope me-1" matPrefix>&nbsp;</i>
      <input matInput [placeholder]="labels?.emailLabel" name="email" formControlName="email"
             [errorStateMatcher]="matcher" required autocomplete="on" aria-label="User mail">
      <mat-error *ngIf="email.errors?.email && !email.errors?.required">{{labels?.emailInvalid}}</mat-error>
      <mat-error *ngIf="email.errors?.required">{{labels?.emailRequired}}</mat-error>
    </mat-form-field>
  </div>
  <div class="submit-row">
    <button type="submit" class="input-submit" [disabled]="!contactFormGroup.valid || inputLoading"
            [class.loading-input]="inputLoading">
      <span>{{labels?.commentButton}}&nbsp;</span>
      <i *ngIf="!inputLoading" class="input-icon fas fa-arrow-right"></i>
      <i *ngIf="inputLoading" class="input-icon fas fa-spinner fa-spin"></i>
    </button>
  </div>
</form>

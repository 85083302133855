// @see https://lukeliutingchun.medium.com/angular-introduction-to-named-router-outlet-and-a-hack-for-custom-url-6ca1cd11fd2a

import {DefaultUrlSerializer, UrlTree} from '@angular/router';

export class CustomUrlSerializer extends DefaultUrlSerializer {
  // Pass url from browser to internal.
  private _reverseUrl(url: string): string {
    if (url.indexOf('/asset-') !== -1 && url.indexOf('/asset-3d') === -1) {
      // Operation to reverse your desired format to the default one.
      const startIndex = url.indexOf('/asset-') + 1;
      const urlSliced = url.slice(-2) === '/.' ? url.slice(startIndex, -2) : url.slice(startIndex);
      const segmentString =
        `/.(${urlSliced.replace('asset-', 'asset:')})`; // .split('/').join('/')
      return url.substring(0, startIndex - 1) + segmentString;
    } else {
      return url;
    }
  }

  // Pass url from internal to browser.
  private _beautifyUrl(url: string): string {
    // Operation to convert the URL into your desired format.
    if (url.indexOf('(asset:') !== -1 && url.indexOf('/asset-3d') === -1) {
      return url
        .replace('/./(', '/')
        .replace('/.(', '/')
        .replace(')', '/')
        .replace('asset:', 'asset-')
        .split('//').join('/');
    } else {
      return url;
    }
  }

  parse(url: string): UrlTree {
    // return super.parse(url);
    return super.parse(this._reverseUrl(url));
  }

  serialize(tree: UrlTree): string {
    // return super.serialize(tree);
    return this._beautifyUrl(super.serialize(tree));
  }
}

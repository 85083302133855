<header id="header" role="banner" class="def_back"
        [ngClass]="{
          'fixed': headerFixed && !isHome,
          'home': isHome
        }">
  <div class="container-lg gx-4 gx-xl-0">
    <div id="logo-full">
      <!--<span *ngIf="event" class="events_item" [id]="event" [title]="event">
        <span></span>
      </span>-->
      <!--
      <img *ngIf="event === 'christmas'" class="events_item" id="christmas-hat" width="43" height="41"
           src="https://tools.obyte.it/img/idrobyte/events_christmas_hat.png" alt="Christmas hat">
      <img *ngIf="event === 'easter'" class="events_item" id="easter-egg" width="47" height="61"
           src="https://tools.obyte.it/img/idrobyte/events_easter_egg.png" alt="Easter egg">
      <img *ngIf="event === 'halloween'" class="events_item" id="halloween-jack" width="47" height="47"
           src="https://tools.obyte.it/img/idrobyte/events_halloween_jack.png" alt="Halloween Jack">
      <img *ngIf="event === 'carnival'" class="events_item" id="carnival-mask" width="54" height="29"
           src="https://tools.obyte.it/img/idrobyte/events_carnival_mask.png" alt="Carnival mask">-->
      <div id="logo">
        <a class="logo" routerLink="/." rel="noopener noreferrer nofollow"><span class="sr-only">SandBay</span></a>
        <a class="logo hover" routerLink="/." rel="noopener noreferrer nofollow"><span class="sr-only">SandBay</span></a>
      </div>
      <div id="motto">Fantasy Meets Technology</div>
    </div>

    <app-menu [headerFixed]="headerFixed && !isHome" class="menu"></app-menu>

    <app-toggles [userName]="userName" class="utils"></app-toggles>

    <div id="auth-block" class="auth_back fadeIn"
         *ngIf="userName">
      <a id="logout-link" (click)="userLogout()">
        <i class="fas fa-sign-out">&nbsp;</i>
      </a>
      <div ngbDropdown placement="bottom-right" id="options-dropdown">
        <a id="options-link" ngbDropdownToggle><i class="fas fa-cog"></i>{{'head.opzioni' | translate }}</a>
        <div ngbDropdownMenu aria-labelledby="options-link" class="auth_back">
          <a ngbDropdownItem routerLink="/user/profile/."><i class="fas fa-id-card">&nbsp;</i>{{'head.profilo' | translate }}</a>
          <a ngbDropdownItem routerLink="/user/password-modify/.">
            <i class="fas fa-user-edit">&nbsp;</i><span [innerHTML]="'passwordMod.title' | translate"></span>
          </a>
          <a ngbDropdownItem *ngIf="isAdmin"
             href="https://tools.obyte.it/dev/LOG_SHOW" target="_blank" rel="noopener noreferrer">
            Admin
          </a>
        </div>
      </div>

      <a id="user-name" routerLink="/user/profile/."><i class="fas fa-user"></i>{{userName}}</a>
    </div>
  </div>
</header>
<div *ngIf="userName" id="auth-space"></div>

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {Subscription} from 'rxjs';
import {Comment} from '@/models/Comment';
import {Contacts} from '@/models/Contacts';
import {SiteService} from '@/services/site.service';
import {UsersService} from '@users/services/users.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./_contacts.component.scss']
})
export class ContactsComponent implements OnInit, OnDestroy {
  @Input() public status: boolean;
  @Input() public labels: Contacts;
  private _itemSubscription: Subscription;
  public commentSent: Comment;
  public MAX_MESSAGE_LENGTH = 1000;
  public inputLoading = false;

  constructor(private _siteService: SiteService,
              private fb: UntypedFormBuilder,
              private usersService: UsersService) {
    this.status = false;
    this.commentSent = new Comment(null, null, null, null, null);
  }

  contactFormGroup = this.fb.group({
    username: ['', [ Validators.required ]],
    email: ['', [ Validators.email, Validators.required ]],
    message: ['', [ Validators.required, ]],
  });
  matcher = new MyErrorStateMatcher();

  get username() {
    return this.contactFormGroup.get('username');
  }
  get email() {
    return this.contactFormGroup.get('email');
  }
  get message() {
    return this.contactFormGroup.get('message');
  }

  ngOnInit(): void {
    // Fill and disable fields on user authenticated.
    if (this.usersService.isAuthenticated()) {
      const username = UsersService.getUsername();
      this.username.setValue(username != null ? username : '');
      this.username.disable();
      const email = UsersService.getEmail();
      this.email.setValue(email != null ? email : '');
      this.email.disable();
    }
  }

  /**
   * Submit contacts form. Send mail to administrator.
   */
  onSubmit() {
    this.inputLoading = true;
    const formValues = this.contactFormGroup.value;
    this._itemSubscription = this._siteService.sendMessage(
      formValues.username,
      formValues.email,
      formValues.message,
    ).subscribe(res => {
      if (res.code === 0) {
        this.status = true;
        this.commentSent.message = res.result.message;
      } else {
        console.warn('Send failed:', res.code);
      }
      this.inputLoading = false;
    });
  }

  ngOnDestroy() {
    if (this._itemSubscription) {
      this._itemSubscription.unsubscribe();
    }
  }
}

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '~/environments/environment';
import {Observable} from 'rxjs';
import {Slider} from '@/models/Slider';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SiteService {
  private readonly api: string;

  constructor(
    private readonly _http: HttpClient) {
    this.api = environment.HOST_SERVICES;
  }

  /**
   * Home function for slider.
   * @deprecated
   */
  getSlider(): Observable<Slider[]> {
    return this._http.get<any>(`${this.api}getSliderNg`).pipe(
      map(res => res.result.map(Slider.adapt))
    );
  }

  /**
   * Utility function to send a comment.
   */
  sendComment(type: string, argument: string, message: string,
              username: string, email: string, website: string): Observable<any> {
    const params = new HttpParams()
      .append('type', type)
      .append('argument', argument)
      .append('message', message)
      .append('username', username)
      .append('email', email)
      .append('website', website);
    return this._http.post(`${this.api}sendComment`, params ); // comment/
  }

  /**
   * Utility function to send a message in site.
   */
  sendMessage(name: string, email: string, message: string): Observable<any> {
    const params = new HttpParams()
      .append('name', name)
      .append('email', email)
      .append('message', message);
    return this._http.post(`${this.api}sendMessage`, params ); // message/
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {Tool} from '@/models/Tool';
import {Router} from '@angular/router';
import {GALLERY_MIN_FILE_EXT, PUBLIC_TOOLS_IMAGES} from '@/Constants';

@Component({
  selector: 'app-tool',
  templateUrl: './tool.component.html',
  styleUrls: ['./_gallery-tools.component.scss']
})
export class ToolComponent implements OnInit {
  @Input() public tool: Tool;
  @Input() public toolType: string;
  public GALLERY_MIN_FILE_EXT = GALLERY_MIN_FILE_EXT;
  public PUBLIC_TOOLS_IMAGES = PUBLIC_TOOLS_IMAGES;

  constructor(private _router: Router) {
  }

  ngOnInit() {}

  showItem(name, link) {
    if (typeof link !== 'undefined' && link && typeof window !== 'undefined') {
      window.open(link, '_blank');
    } else {
      void this._router.navigate([{ outlets: { asset: ['tool', name] } }]);
    }
  }
}

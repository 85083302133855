import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {GalleriesRoutingModule} from './galleries-routing.module';
import {GallerySearchComponent} from './components/gallery-search/gallery-search.component';
import {GalleryModelsComponent} from './components/gallery-models/gallery-models.component';
import {GalleryModelsItemComponent} from './components/gallery-models/item/gallery-models-item.component';
import {GalleryGraphicsComponent} from './components/gallery-graphics/gallery-graphics.component';
import {GalleryGraphicsItemComponent} from './components/gallery-graphics/item/gallery-graphics-item.component';
import {GalleryWebsitesComponent} from './components/gallery-websites/gallery-websites.component';
import {GalleryToolsComponent} from './components/gallery-tools/gallery-tools.component';
import {GalleryToolsItemComponent} from './components/gallery-tools/item/gallery-tools-item.component';
import {ToolComponent} from './components/gallery-tools/tool.component';
import {HttpLoaderFactory, SharedModule} from '@/shared.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {NgxPaginationModule} from 'ngx-pagination';
import {CommentsComponent} from '@/components/common/comments/comments.component';
import {SafePipe} from '@/pipes/safe.pipe';
import {NguCarouselModule} from '@ngu/carousel';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {MatExpansionModule} from '@angular/material/expansion';
import {FiltersComponent} from './components/gallery-models/filters/filters.component';
import { GalleryGraphicsCategoryComponent } from './components/gallery-graphics/category/gallery-graphics-category.component';
import {BreadcrumbsComponent} from '@/components/common/breadcrumbs/breadcrumbs.component';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';


@NgModule({
  declarations: [
    GallerySearchComponent,
    GalleryModelsComponent,
    GalleryModelsItemComponent,
    GalleryGraphicsComponent,
    GalleryGraphicsCategoryComponent,
    GalleryGraphicsItemComponent,
    GalleryWebsitesComponent,
    GalleryToolsComponent,
    GalleryToolsItemComponent,
    ToolComponent,
    CommentsComponent,
    SafePipe,
    FiltersComponent,
    BreadcrumbsComponent,
  ],
  imports: [
    SharedModule,
    CommonModule,
    NgbTooltipModule,
    MatExpansionModule,
    GalleriesRoutingModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NguCarouselModule,
    NgxPaginationModule,
    MatButtonModule,
  ],
})
export class GalleriesModule { }

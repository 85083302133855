<app-header *ngIf="showHeader" [isHome]="isHome"></app-header>

<main>
  <router-outlet name="asset" id="container-modal"></router-outlet>

  <router-outlet></router-outlet>
</main>

<app-footer *ngIf="showFooter"></app-footer>

<app-backgrounds></app-backgrounds>

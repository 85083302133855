import {Component, OnDestroy, OnInit} from '@angular/core';
import {OBYTE_HOST_UTIL, PUBLIC_SLIDERS_IMAGES} from '@/Constants';
import {Meta, Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {Slider} from '@/models/Slider';
import {SiteService} from '@/services/site.service';
import {UtilsService} from '@/services/utils.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./_home.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent implements OnInit, OnDestroy {
  public OBYTE_HOST_UTIL = OBYTE_HOST_UTIL;
  // public PUBLIC_SLIDERS_IMAGES_SET = PUBLIC_SLIDERS_IMAGES + 'srcset/';
  public items: Slider[] = [];
  private sub: Subscription;
  public sections = [
    {
      link: '/gallery-models/.',
      icon: 'fa-cubes fas',
      title: 'sez3dModels',
      desc: 'desc3dModels',
      img: 'models'
    },
    {
      link: '/gallery-websites/.',
      icon: 'fa-atlas fas', // 'globe',
      title: 'sezWebsites',
      desc: 'descWebsites',
      img: 'websites'
    },
    {
      link: '/gallery-graphics/.',
      icon: 'fa-map far',
      title: 'sezGraphics',
      desc: 'descGraphics',
      img: 'graphics'
    },
    {
      link: '/gallery-tools/.',
      icon: 'fa-toolbox fas', // 'tools', // 'wrench',
      title: 'sezTools',
      desc: 'descTools',
      img: 'tools'
    }
  ];

  constructor(private _siteService: SiteService,
              private _utilsService: UtilsService,
              private _meta: Meta,
              private _title: Title) {}

  ngOnInit() {
    /* TODO https://sketchfab.com/3d-viewer */
    this._title.setTitle('SandBay - Fantasy Meets Technology');
    this._meta.updateTag({ name: 'description', content: `Where fantasy meets technology. 3D models, renders, web sites, tools.` });
    this._meta.updateTag({property: 'og:title', content: 'SandBay - Fantasy Meets Technology'});
    this._meta.updateTag({property: 'og:description', content: `Where fantasy meets technology. 3D models, renders, web sites, tools.`});
    this._meta.updateTag({property: 'og:image', content: 'https://www.sandbay.it/assets/logo.png'});
    this._meta.updateTag({property: 'og:url', content: `https://www.sandbay.it/`});
    this._utilsService.createLinkForCanonicalURL(`https://www.sandbay.it/`);
    // this.sub = this._siteService.getSlider().subscribe(res => {
    //   this.items = res;
    // });
  }

  ngOnDestroy() {
    // this.sub.unsubscribe();
  }

  /**
   * trackBy for performance.
   */
  public trackById(index, item) {
    return item.title;
  }
}

<div *ngFor="let item of comments" class="comment-container">
  <div class="comment-body def_back" [innerHTML]="item.message"></div>
  <span class="comment-write-date">{{item.writeDate | date:'MM/dd/yyyy'}}</span>
  <span class="comment-user">{{item.username}}</span>
</div>
<div class="comment-container">
  <div *ngIf="status" class="comment-container">
    <p>{{'comments.sent' | translate }}</p>
    <div class="comment-body def_back" [innerHTML]="commentSent.message"></div>
    <span class="comment-user">{{commentSent.username}}</span>
  </div>
  <mat-expansion-panel *ngIf="!status" class="comment_form def_back">
    <mat-expansion-panel-header>
      <mat-panel-title>{{'comments.write' | translate }}</mat-panel-title>
      <mat-panel-description></mat-panel-description>
    </mat-expansion-panel-header>
    <form [formGroup]="commentFormGroup" (ngSubmit)="onSubmit()">
      <div class="row">
        <mat-form-field appearance="fill" class="col-12">
          <i class="input-icon fas fa-comment" matPrefix>&nbsp;</i>
          <textarea matInput id="message" #message placeholder="{{'comments.write' | translate }}" name="message"
                    matTextareaAutosize matAutosizeMinRows="4" matAutosizeMaxRows="10"
                    [maxlength]="MAX_MESSAGE_LENGTH" formControlName="message"
                    [errorStateMatcher]="matcher" required></textarea>
          <mat-hint align="start">Max {{MAX_MESSAGE_LENGTH}} {{'comments.charMax' | translate }}</mat-hint>
          <mat-hint align="end">{{message.value.length}} / {{MAX_MESSAGE_LENGTH}}</mat-hint>
          <mat-error *ngIf="commentFormGroup.get('message').hasError('required')"
                     [innerHTML]="'forms.isRequired' | translate">
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field appearance="fill" class="mt-4 col-md-6 col-sm-12">
          <i class="input-icon fas fa-user" matPrefix>&nbsp;</i>
          <input matInput placeholder="Username" name="username" formControlName="username"
                 [errorStateMatcher]="matcher" required>
          <mat-error *ngIf="commentFormGroup.get('username').hasError('required')"
                     [innerHTML]="'forms.isRequired' | translate">
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="mt-4 col-md-6 col-sm-12">
          <i class="input-icon far fa-envelope me-1" matPrefix>&nbsp;</i>
          <input matInput placeholder="Email" name="email" formControlName="email"
                 [errorStateMatcher]="matcher" required>
          <mat-error *ngIf="commentFormGroup.get('email').hasError('email') && !commentFormGroup.get('email').hasError('required')"
                     [innerHTML]="'forms.emailErrorValid' | translate">
          </mat-error>
          <mat-error *ngIf="commentFormGroup.get('email').hasError('required')"
                     [innerHTML]="'forms.isRequired' | translate">
          </mat-error>
        </mat-form-field>
      </div>
      <div class="submit-row">
        <span class="float-start" *ngIf="errorMessages">
          <span *ngFor="let aMessage of errorMessages" class="error-label"
                [innerHTML]="(errorMessages.length > 1 ? '- ' : '') + (('errors.' + aMessage) | translate)"></span>
        </span>
        <button type="submit" class="input-submit" [disabled]="!commentFormGroup.valid"
            (click)="inputLoading = true" [class.loading-input]="inputLoading">
          <span>{{'comments.send' | translate }}&nbsp;</span>
          <i *ngIf="!inputLoading" class="input-icon fas fa-arrow-right"></i>
          <i *ngIf="inputLoading" class="input-icon fas fa-spinner fa-spin"></i>
        </button>
      </div>
    </form>
  </mat-expansion-panel>

</div>

<nav role="navigation" aria-label="Breadcrumbs" class="breadcrumbs">
  <ol class="text-center mb-4">
    <li>
      <a href="https://www.sandbay.it/" rel="home">
        <span>Home</span>
      </a>
    </li>
    <li *ngFor="let node of nodes">
      <a *ngIf="node.url" [href]="'/' + node.url + '/.'">
        <span>{{node.label | titlecase}}</span>
      </a>
      <span *ngIf="!node.url">{{node.label | titlecase}}</span>
    </li>
  </ol>
</nav>

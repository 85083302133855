import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {Logger} from './services/logger.service';
import {SiteService} from '@/services/site.service';

// import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./_app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  name = 'SandBay';
  showHeader = false;
  showFooter = false;
  previousUrl: string;
  private sub: Subscription;
  isHome: boolean;


  constructor(
    private renderer: Renderer2,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private logger: Logger,
    private siteService: SiteService,
  ) {
  }

  // private myTemplate: any = '';
  // constructor(private readonly _http: HttpClient) {
  //   this._http.get<String>('/assets/page.html',
  //     {responseType: 'text'}).subscribe(html => this.myTemplate = html);
  // }

  ngOnInit() {
    this.sub = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // To hide header/footer
        this.showHeader = this.activatedRoute.firstChild.snapshot.data.showHeader !== false;
        this.showFooter = this.activatedRoute.firstChild.snapshot.data.showFooter !== false;

        // To add page class to body
        const lastUrlSlash = event.url.slice(1).indexOf('/') + 1;
        const currentUrlSlug = event.url.slice(1, lastUrlSlash > 0 ? lastUrlSlash : 100)
          .replace(/[^a-z0-9-]/gi, '');
        if (typeof document !== 'undefined') {
          if (this.previousUrl) {
            this.renderer.removeClass(document.body, this.previousUrl);
          } else {
            this.renderer.removeClass(document.body, 'home');
          }
          // console.log('currentUrlSlug:', currentUrlSlug);
          if (currentUrlSlug) {
            this.renderer.addClass(document.body, currentUrlSlug);
          } else if (currentUrlSlug.length === 0) {
            this.renderer.addClass(document.body, 'home');
          }
        }

        this.isHome = (currentUrlSlug.length === 0 || currentUrlSlug === 'home');
        this.previousUrl = currentUrlSlug;
      }
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}

<!--<div id="mob-menu-back" class="mobile" [class.navOpen]="navOpen"></div>-->

<nav id="menu" class="navbar navbar-default" role="navigation" [class.fixed]="headerFixed">
  <div id="menu-toggle" class="mobile" tabindex="0" title="Menu toggle"
       (click)="onNavOpen()">
    <i class="icon-bar"></i>
    <i class="icon-bar"></i>
    <i class="icon-bar"></i>
  </div>
  <div class="mobile-menu-back" (click)="onNavClose()" [class.navOpen]="navOpen">
    <ul id="main-menu" [class.navOpen]="navOpen">
      <!--<li class="menu-item"><a routerLink="/." routerLinkActive="selected" rel="noopener noreferrer nofollow">
        <span class="menu-link double-line">
          <i class="fas fa-home" aria-hidden="true"></i><br>
          <span>{{'menu.sezHome' | translate }}</span>
        </span>
      </a></li>-->
      <li class="menu-item">
        <a routerLink="/gallery-models/." routerLinkActive="selected">
          <span class="menu-link double-line">
            <i class="fas fa-cube" aria-hidden="true"></i><br>
            <span [innerHTML]="'menu.sez3dModelsDouble' | translate"></span>
          </span>
        </a>
        <ul class="menu-sub">
          <li class="menu-sub-item" *ngFor="let model_type of MODELS_TYPES">
            <a class="{{model_type}}" [routerLink]="'/gallery-models/' + model_type + '/.'">
              <i class="fas fa-{{GALLERY_MODELS_TYPES[model_type]}}" aria-hidden="true"></i>
              {{'menu.sez3dModels' + (model_type | titlecase) | translate }}
            </a>
          </li>
        </ul>
      </li>
      <li class="menu-item">
        <a routerLink="/gallery-graphics/." routerLinkActive="selected">
          <span class="menu-link double-line">
            <i class="fas fa-map" aria-hidden="true"></i><br>
            <span [innerHTML]="'menu.sezGraphics' | translate"></span>
          </span>
        </a>
        <!--<ul class="menu-sub graphics">
          <li class="menu-sub-item" *ngFor="let graphic_type of GRAPHICS_TYPES">
            <a class="{{graphic_type}}" [routerLink]="'/gallery-graphics/' + graphic_type + '/.'">
              <i class="fas fa-{{GALLERY_GRAPHICS_TYPES[graphic_type]}}" aria-hidden="true"></i>
              {{ graphic_type | titlecase }}
            </a>
          </li>
        </ul>-->
      </li>
      <li class="menu-item"><a routerLink="/gallery-websites/." routerLinkActive="selected">
        <span class="menu-link double-line">
          <i class="fas fa-globe" aria-hidden="true"></i><br>
          <span>{{'menu.sezWebsites' | translate }}</span>
        </span>
      </a></li>
      <li class="menu-item"><a routerLink="/gallery-tools/." routerLinkActive="selected">
        <span class="menu-link double-line">
          <i class="fas fa-toolbox" aria-hidden="true"></i><br>
          <!--<i class="fas fa-wrench" aria-hidden="true"></i><br>-->
          <span>{{'menu.sezTools' | translate }}</span>
        </span>
      </a></li>
      <li class="menu-item"><a href="https://blog.sandbay.it/"
                               target="_blank" rel="noopener noreferrer">
        <span class="menu-link double-line">
          <i class="fas fa-book" aria-hidden="true"></i>
          <i class="fas fa-external-link-alt external"></i>
          <br>
          <span>Blog</span>
        </span>
      </a></li>
      <li class="menu-item">
        <a routerLink="/about/." routerLinkActive="selected">
          <span class="menu-link double-line">
            <i class="fas fa-users" aria-hidden="true"></i><br>
            <span>{{'menu.sezChiSiamo' | translate }}</span>
          </span>
        </a>
        <!--<ul>
          <li class="menu-item under">
            <a routerLink="/about" routerLinkActive="selected">
              <span class="menu-link">About me</span>
            </a>
          </li>
        </ul>-->
      </li>
    </ul>
  </div>
</nav>

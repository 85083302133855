import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {CACHE_TYPE} from '@/Constants';
import {StorageService} from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  private cache: any = { };
  private CACHE_PREFIX = 'cache_';

  constructor() { }

  put(url: string, response: HttpResponse<any>): void {
    this.cache[url] = response;
    switch (CACHE_TYPE) {
      case 'session': StorageService.setSession(this.CACHE_PREFIX + this.cache[url], response); break;
      // case 'var': this.cache[url] = response; break;
      case 'local': StorageService.setLocal(this.CACHE_PREFIX + this.cache[url], response); break;
      default: break;
    }
  }

  get(url: string): HttpResponse<any> | undefined {
    switch (CACHE_TYPE) {
      case 'session': return StorageService.getSession(this.CACHE_PREFIX + this.cache[url]);
      case 'var': return this.cache[url];
      case 'local': return StorageService.getLocal(this.CACHE_PREFIX + this.cache[url]);
    }
    return this.cache[url];
  }

  invalidateCache(): void {
    this.cache = { };
  }
}

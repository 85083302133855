<form id="search-form" [formGroup]="searchFormGroup" (ngSubmit)="onSubmit()">
  <mat-form-field appearance="fill" class="input-medium-width">
    <label for="keywords" class="d-none">Search with keyword</label>
    <input #searchField matInput placeholder="Search..." name="keywords"
           id="keywords" formControlName="keywords" required>
  </mat-form-field>
  <!-- TODO Select per il tipo di ricerca Es.: All, 3D Models, Graphics
  <mat-form-field appearance="fill">
  -->
  <button type="submit" class="input-submit" [disabled]="!searchFormGroup.valid" [class.loading-input]="inputLoading">
          <!--(click)="inputLoading = true">-->
    <i *ngIf="!inputLoading" class="input-icon fas fa-search"></i>
    <i *ngIf="inputLoading" class="input-icon fas fa-spinner fa-spin"></i>
  </button>
</form>

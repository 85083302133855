import {Component, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {EVENTS} from '@/Constants';
import {UsersService} from '@/users/services/users.service';
import {AuthConfig, OAuthService} from 'angular-oauth2-oidc';
import {User} from '@users/models/User';
// import {SiteService} from '@/services/site.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./_header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  public headerFixed = true;
  public event = '';
  public userName = null;
  public isAdmin = false;
  @Input() public isHome: boolean;

  constructor(public usersService: UsersService) {
    this.usersService.authenticated.subscribe(
      (authenticated: boolean) => {
        if (authenticated) {
          this.userName = UsersService.getOnlyName();
          this.isAdmin = UsersService.isAdmin();
        } else {
          this.userName = null;
          this.isAdmin = false;
        }
      });

    // Automatic event system.
    this.setHolidayEvent();
  }

  // @Deprecated
  // @HostListener('window:scroll', ['$event'])
  // onScrollEvent() { // ($event) {
  //   if (typeof window !== 'undefined' || typeof document !== 'undefined') {
  //     const topDistance = (typeof window !== 'undefined' ? window.pageYOffset : null) || document.documentElement.scrollTop || document.body.scrollTop || 0;
  //     if (!this.headerFixed && topDistance >= 10) {
  //       this.headerFixed = true;
  //     } else if (this.headerFixed && topDistance < 10) {
  //       this.headerFixed = false;
  //     }
  //   }
  // }

  ngOnInit(): void {
    if (this.usersService.isAuthenticated()) {
      this.userName = UsersService.getOnlyName();
      this.isAdmin = UsersService.isAdmin();
    }
  }
  ngOnDestroy(): void {
    this.usersService.authenticated.unsubscribe();
  }
  userLogout(): void {
    this.usersService.logout();
  }

  private setHolidayEvent(): void {
    const year = new Date().getUTCFullYear();
    const today = new Date().getTime();

    this.event = EVENTS.find(e => {
      const from = new Date(`${e.start}/${year}`).getTime();
      const to = new Date(`${e.end}/${year + e.yearAdd}`).getTime();
      return today >= from && today <= to;
    })?.event;
  }
}

import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private static storage: Storage; // = localStorage;

  constructor() {}

  private static getInstance(): Storage {
    return (typeof window !== 'undefined') ? localStorage : null;
  }

  public static get(key: string): string {
    if (typeof window !== 'undefined') {
      return StorageService.getInstance().getItem(key);
    }
    return null;
  }

  public static set(key: string, value: string) {
    if (typeof window !== 'undefined') {
      StorageService.getInstance().setItem(key, value);
    }
  }

  public static remove(key: string) {
    if (typeof window !== 'undefined') {
      StorageService.getInstance().removeItem(key);
    }
  }

  public static getLocal = (key: string): any => {
    return JSON.parse(StorageService.getInstance().getItem(StorageService.cleanUp(key)) || null);
  }

  public static setLocal = (key: string, value: any) => {
    // console.log('KEY: ', key, StorageService.cleanUp(key));
    StorageService.getInstance().setItem(StorageService.cleanUp(key), JSON.stringify(value));
  }

  public static getSession = (key: string): any => {
    if (typeof window !== 'undefined') {
      StorageService.storage = sessionStorage;
      StorageService.get(StorageService.cleanUp(key));
      StorageService.storage = localStorage;
    }
  }

  public static setSession = (key: string, value: any) => {
    if (typeof window !== 'undefined') {
      StorageService.storage = sessionStorage;
      StorageService.set(StorageService.cleanUp(key), value);
      StorageService.storage = localStorage;
    }
  }

  public static clear() {
    StorageService.getInstance().clear();
  }

  private static cleanUp(s: string) {
    return s.toLowerCase().replace(/[^a-zA-Z0-9_]+/g, '');
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Meta, Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {GALLERY_FILE_EXT, PUBLIC_TOOLS_IMAGES, TITLE_APPEND} from '@/Constants';
import {GalleryService} from '@galleries/services/gallery.service';
import {ToolsItem, Version} from '@/models/ToolsItem';
import {Logger} from '@/services/logger.service';
import {UtilsService} from '@/services/utils.service';

@Component({
  selector: 'app-gallery-tools-item',
  templateUrl: './gallery-tools-item.component.html',
  styleUrls: ['./_gallery-tools-item.component.scss']
})
export class GalleryToolsItemComponent implements OnInit, OnDestroy {
  public PUBLIC_TOOLS_IMAGES = PUBLIC_TOOLS_IMAGES;
  public GALLERY_FILE_EXT = GALLERY_FILE_EXT;
  public TOOL_FILE_EXT = '.png';
  public item: ToolsItem;
  public itemVersionNames: string[];
  public itemVersions: Version[][];
  private _itemName: string;
  public loadingDownload = false;
  private _paramsSubscription: Subscription;
  private _itemSubscription: Subscription;
  public galleryError: boolean;
  public galleryNotFound: boolean;

  constructor(private _title: Title,
              private _meta: Meta,
              private _router: Router,
              private _activatedRoute: ActivatedRoute,
              private _utilsService: UtilsService,
              private _galleryService: GalleryService) {}

  ngOnInit() {
    this._paramsSubscription = this._activatedRoute.params.subscribe((params: Params) => {
      this._itemName = params.name;
    });
    this._itemSubscription = this._galleryService.getToolsItem(this._itemName)
      .subscribe({
        next: res => {
          if (res != null) {
            this.item = res;
            if (res.versions) {
              this.itemVersionNames = res.versionNames;
              this.itemVersions = res.versions;
            }
            this._title.setTitle(`Tools - ${this.item.title}${TITLE_APPEND}`);
            this._meta.updateTag({name: 'description', content: `Tool: ${this.item.title}.`});
            this._meta.updateTag({property: 'og:title', content: `Tools - ${this.item.title}${TITLE_APPEND}`});
            this._meta.updateTag({property: 'og:description', content: `Tool: ${this.item.title}.`});
            this._meta.updateTag({property: 'og:image', content: 'https://www.sandbay.it/assets/logo.png'});
            this._meta.updateTag({property: 'og:url', content: `https://www.sandbay.it/gallery-tools/asset-tool/${this._itemName}/`});
            this._utilsService.createLinkForCanonicalURL(`https://www.sandbay.it/gallery-tools/asset-tool/${this._itemName}/`);
            // Logger.debug('this.item tool:', this.item);
          } else {
            this.galleryNotFound = true;
          }
        },
        error: err => {
          this.galleryError = UtilsService.handleGalleryError(err);
        }
      });
  }

  ngOnDestroy() {
    this._paramsSubscription.unsubscribe();
    this._itemSubscription.unsubscribe();
  }

  saveItem(itemName: string): boolean {
    return this._galleryService.downloadFile(itemName);
  }

  // changeItem(paramName) {
  //   void this._router.navigate([{ outlets: { asset: ['tool', paramName] } }]);
  // }

  close() {
    this._title.setTitle(`Tools${TITLE_APPEND}`);
    this._meta.updateTag({ name: 'description', content: `Tools and games gallery.` });
    this._meta.updateTag({property: 'og:title', content: `Tools${TITLE_APPEND}`});
    this._meta.updateTag({property: 'og:description', content: `Tools and games gallery.`});
    this._meta.updateTag({property: 'og:image', content: 'https://www.sandbay.it/assets/logo.png'});
    this._meta.updateTag({property: 'og:url', content: `https://www.sandbay.it/gallery-tools/`});
    this._utilsService.createLinkForCanonicalURL(`https://www.sandbay.it/gallery-tools/`);
    void this._router.navigate([{ outlets: { asset: null } }]);
  }
}

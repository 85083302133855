import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Comment} from '@/models/Comment';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {MyErrorStateMatcher} from '@/shared.module';
import {UtilsService} from '@/services/utils.service';
import {SiteService} from '@/services/site.service';
import {UsersService} from '@users/services/users.service';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./_comments.component.scss']
})
export class CommentsComponent implements OnInit, OnDestroy {
  @Input() public argument: string;
  @Input() public comments: Comment[];
  @Input() public status: boolean;
  public commentSent: Comment;
  private _itemSubscription: Subscription;
  public MAX_MESSAGE_LENGTH = 200;
  public inputLoading = false;
  public errorMessages = [];
  private jwtUsername: string;
  private jwtEmail: string;

  constructor(private _siteService: SiteService,
              private fb: UntypedFormBuilder,
              private usersService: UsersService) {
    this.status = false;
    this.commentSent = new Comment(null, null, null, null, null);
  }

  public commentFormGroup = this.fb.group({
    username: ['', [ Validators.required, Validators.max(40) ]],
    email: ['', [ Validators.email, Validators.required, Validators.max(50) ]],
    message: ['', [ Validators.required, Validators.max(this.MAX_MESSAGE_LENGTH) ]],
  });
  public matcher = new MyErrorStateMatcher();

  get username() {
    return this.commentFormGroup.get('username');
  }
  get email() {
    return this.commentFormGroup.get('email');
  }
  get message() {
    return this.commentFormGroup.get('message');
  }

  ngOnInit(): void {
    // Fill and disable fields on user authenticated.
    if (this.usersService.isAuthenticated()) {
      const username = this.jwtUsername = UsersService.getUsername().split(' ')[0];
      this.username.setValue(username != null ? username : '');
      this.username.disable();
      const email = this.jwtEmail = UsersService.getEmail();
      this.email.setValue(email != null ? email : '');
      this.email.disable();
    }
  }

  /**
   * Submit comments form.
   */
  onSubmit() {
    const formValues = this.commentFormGroup.value;
    this._itemSubscription = this._siteService.sendComment(
      'GALLERY_MODELS',
      this.argument,
      formValues.message,
      formValues.username || this.jwtUsername,
      formValues.email || this.jwtEmail,
      '',
      ).subscribe(res => {
        if (res.result != null && res.code === 0) {
          this.status = true;
          this.commentSent.message = res.result.message;
          this.commentSent.username = res.result.username;
          this.commentSent.writeDate = res.result.writeDate;
        } else {

          this.errorMessages = UtilsService.handleError(res);
          this.inputLoading = false;
        }
      });
  }

  ngOnDestroy() {
    if (this._itemSubscription) {
      this._itemSubscription.unsubscribe();
    }
  }
}

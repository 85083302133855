import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FiltersConfig, GALLERY_ITEMSPERPAGE_DEFAULT, GALLERY_ITEMSPERPAGE_LIST} from '@/Constants';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {
  @Input() public filterId: string;
  @Output() filtering = new EventEmitter<FiltersConfig>();
  @Input() public configs: FiltersConfig;
  @Input() public isFiltered: boolean;
  @Input() public count: number;
  @Input() public directionTop = false; // 'top' || 'down'
  public GALLERY_ITEMSPERPAGE_LIST = GALLERY_ITEMSPERPAGE_LIST;
  public itemsPerPageStatus: boolean[] = GALLERY_ITEMSPERPAGE_LIST.map(
    i => i === GALLERY_ITEMSPERPAGE_DEFAULT
  );
  open: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Configs click event (items per page, filter for collections, filter for downloadable items).
   * @param event [check what configuration to change]
   * @param i [items per page]
   */
  onConfigs(event, i = 100) {
    if (i < 100) {
      this.itemsPerPageStatus = this.itemsPerPageStatus.map((x, j) => {
        return j === i;
      });
      this.configs.itemsPerPage = this.GALLERY_ITEMSPERPAGE_LIST[i]; // set items per page
    }
    if (event.target.checked) {
      this.configs[event.target.name] = event.target.checked; // set configuration filter
    }
    this.configs.isFiltered =  // Set filters button highlighted.
      this.configs.collections
      || this.configs.links
      || this.configs.lods;

    // this.getItems();
    this.filtering.emit(this.configs);
  }

  checkDropDown(open: boolean) {
    this.open = open;
  }
}

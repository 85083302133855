<div *ngIf="visible" role="dialog" class="modal" tabindex="-1" [class.fullscreen]="fullscreen">
  <div [@modal] id="modal__inner" class="def_back" [class.flipOutX]="unmount">
    <header class="modal__header">
      <button type="button" (click)="close()" class="modal__close" title="Close" aria-label="Close modal"></button>
    </header>
    <div class="modal__view">
      <ng-content></ng-content>
    </div>
  </div>
  <div (click)="close()" class="modal-background"></div>
</div>

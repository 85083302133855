<footer id="footer" class="fadeIn def_back">
  <nav id="footer-menu">
    <a routerLink="/gallery-models/." routerLinkActive="selected">{{'menu.sez3dModels' | translate }}</a>
    <a routerLink="/gallery-websites/." routerLinkActive="selected">{{'menu.sezWebsites' | translate }}</a>
    <a routerLink="/gallery-graphics/." routerLinkActive="selected" [innerHTML]="'menu.sezGraphics' | translate"></a>
    <a routerLink="/gallery-tools/." routerLinkActive="selected">{{'menu.sezTools' | translate }}</a>
    <a routerLink="/about/." routerLinkActive="selected">{{'menu.sezChiSiamo' | translate }}</a>
  </nav>
  <span>&copy; 2009-{{currentYear | date:'yyyy'}} sandbay.it. All rights reserved.
    <a id="footer-privacy-link" href="https://tools.obyte.it/public/docs/privacy_policy_en.html"
       target="_blank" rel="noopener noreferrer">
      Privacy
    </a>
    <br/>Optimized for all the major browsers.
  </span>
  <div id="langs-wrapper">
    <div id="langs">
      <span class="lang-fill"></span>
      <span *ngFor="let lang of langs; trackBy: trackById" (click)="switchLanguage(lang)"
            class="lang-item {{lang}}"></span>
    </div>
  </div>
</footer>

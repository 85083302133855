export class ToolsItem {

  constructor(
    public name: string,
    public type: string,
    public title: string,
    public titleImgs: string,
    public descriptionExt: string,
    public descriptionExtEn: string,
    public download: string,
    public countDownload: string,
    public year: string,
    public versionNames?: string[] | null,
    public versions?: Version[][] | null,
    public page?: boolean,
    public image?: string,
    public donate?: boolean,
  ) {}

  public static adapt(item: any): ToolsItem | null {
    return (item != null && item?.title != null) ? new ToolsItem(
      item.name,
      item.type,
      item.title,
      item.titleImgs,
      item.description_ext || item.descriptionExt,
      item.description_ext_en || item.descriptionExtEn,
      item.download,
      item.countDownload,
      item.year,
      item.versions ? Object.keys(item.versions) : null,
      item.versions ? Object.values(item.versions).map(
        (vs: Version[]) => vs.map(Version.adapt)
      ) : null,
      item.page,
      item.image,
      item.donate,
    ) : null;
  }
}

export class Version {

  constructor(
    public version: string,
    public description: string,
    public added: string
  ) {}

  public static adapt(item): Version {
    return new Version(
      item?.version,
      item?.description,
      item?.added,
    );
  }
}

import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {BackgroundDirective} from '@/users/services/background.service';

@Component({
  selector: 'app-toggles',
  templateUrl: './toggles.component.html',
  styleUrls: ['./_toggles.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TogglesComponent implements OnInit {
  @Input() userName = null;
  public backsOpen = false;
  public searchOpen = false;

  constructor(
    public router: Router,
    private backgroundDirective: BackgroundDirective,
  ) {}

  ngOnInit(): void {
  }

  switchTheme(theme: string): void {
    this.backgroundDirective.switchTheme(theme);
  }

  isSearchUrl() {
    return this.router.url.includes('search');
  }
}

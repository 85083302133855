import {Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {NgcCookieConsentService} from 'ngx-cookieconsent';
import {DEFAULT_LANG, PORTALS_LANGUAGES} from '@/Constants';
import {UsersService} from '@/users/services/users.service';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./_footer.component.scss']
})
export class FooterComponent implements OnInit {
  public currentYear: Date = new Date();
  public currentLang = DEFAULT_LANG;
  private _navigate: Promise<boolean>;
  langs = ['en', 'it', 'es', 'pirate'];

  constructor(private _router: Router,
              private translate: TranslateService,
              private ccService: NgcCookieConsentService,
              @Inject(DOCUMENT) private document: Document) {
    this.setDefaultLanguage();
    this.setCookieBarLanguage();
  }

  private setDefaultLanguage() {
    let userLang = UsersService.getLang();
    userLang = (userLang !== 'pirate' ? userLang : 'en');
    if (typeof navigator !== 'undefined') {
      userLang = userLang || navigator.language;
    }
    if (userLang.length > 2) {
      userLang = userLang.split('-')[0];
    }
    if (PORTALS_LANGUAGES.indexOf(userLang) > -1) {
      this.currentLang = userLang;
    }
    this.translate.setDefaultLang(this.currentLang); // set default language
    this.document.documentElement.lang = this.currentLang; // set document lang attribute
    this.changeLangsOrder(this.currentLang);
  }

  ngOnInit() {
  }

  showItem(name) {
    this._navigate = this._router.navigate([{ outlets: { asset: ['tool', name] } }]);
  }

  switchLanguage(language: string) {
    if (this.currentLang !== language) {
      this.currentLang = language;
      console.log('Switch language:', language);
      UsersService.setLang(language);
      this.translate.use((language === 'pirate') ? 'en' : language);
      this.document.documentElement.lang = language;
      this.changeLangsOrder(language);
    }
  }

  setCookieBarLanguage() {
    this.translate
      .get(['general.lang', 'general.cookieText'])
      .subscribe(data => {
        const ccConfig = this.ccService.getConfig();
        if (typeof ccConfig !== 'undefined') {
          ccConfig.content = ccConfig.content || {} ;
          // Override default messages with the translated ones
          ccConfig.content.message = data['general.cookieText'];
          ccConfig.content.link = 'Cookie Policy';
          ccConfig.content.href = 'http://tools.obyte.it/public/docs/privacy_policy_'
            + ((data['general.lang'] === 'it') ? 'it' : 'en') + '.html';
          this.ccService.destroy(); // remove previous cookie bar (with default messages)
          this.ccService.init(ccConfig); // update config with translated messages
        }
      });
  }

  changeLangsOrder(currentLang: string) {
    const langs = this.langs;
    const langIndex = langs.indexOf(currentLang);
    langs.splice(langIndex, 1);
    langs.unshift(currentLang);
    this.langs = langs;
  }

  /**
   * trackBy for performance.
   */
  public trackById(index, item) {
    return item;
  }
}

<div ngbDropdown [placement]="directionTop ? 'top-right' : 'bottom-right'"
     class="ngb-dropdown" (openChange)="checkDropDown($event)"
      [ngClass]="directionTop ? 'top' : 'down'">
  <button type="button" class="btn btn-outline-primary float-end filters-dropdown" [id]="'dropdownBasic-' + filterId"
          [ngClass]="configs?.isFiltered ? 'on' : 'off'" ngbDropdownToggle>
    <span *ngIf="count" class="filters-count">{{count}}&nbsp;</span>
    <i [ngClass]="open ? 'open' : null" class="fa-cog fas"></i><span class="sr-only">Filters</span>
  </button>
  <div ngbDropdownMenu aria-labelledby="dropdownBasic" class="dropdown-menu">
    <div class="row">
      <div class="col-4 items-per-page">
        <span class="mb-1 label" [innerHTML]="'models.ipp' | translate"></span>
        <button ngbDropdownItem type="button" *ngFor="let v of GALLERY_ITEMSPERPAGE_LIST; let i = index"
                (click)="onConfigs($event, i);" [class.selected]="configs?.itemsPerPage === v"
        >{{v}}</button>
      </div>
      <div class="col-8">
        <div class="form-check form-switch float-start">
          <input type="checkbox" class="form-check-input" [id]="'filterCollections-' + filterId"
                 (change)="onConfigs($event)" [(ngModel)]="configs.collections">
          <label class="form-check-label" [for]="'filterCollections-' + filterId">{{'models.filterCollections' | translate}}</label>
        </div>
        <div class="form-check form-switch float-start mt-2">
          <input type="checkbox" class="form-check-input" [id]="'filterLinks-' + filterId"
                 (change)="onConfigs($event)" [(ngModel)]="configs.links">
          <label class="form-check-label" [for]="'filterLinks-' + filterId">{{'models.filterLinks' | translate}}</label>
        </div>
        <div class="form-check form-switch float-start mt-2">
          <input type="checkbox" class="form-check-input" [id]="'filterLods-' + filterId" id=""
                 (change)="onConfigs($event)" [(ngModel)]="configs.lods">
          <label class="form-check-label" [for]="'filterLods-' + filterId">{{'models.filterLods' | translate}}</label>
        </div>
      </div>
    </div>
  </div>
</div>

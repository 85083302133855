<app-modal [visible]="true"
           [fullscreen]="isFullScreen"
           (visibleChange)="close()">
  <!-- (next)="changeItem(itemNext?.name)"
           (previous)="changeItem(itemPrev?.name)" -->
  <div *ngIf="!item && !galleryError && !galleryNotFound" class="lds-css">
    <app-loading></app-loading>
  </div>
  <div *ngIf="!item && galleryError" class="gallery-error">
    <i class="far fa-times-circle"></i>
    {{'errors.UNKNOWN' | translate}}
  </div>
  <div *ngIf="!item && galleryNotFound" class="gallery-error">
    <div class="error-code">404</div>
    <div>{{'errors.NOT_FOUND' | translate}}</div>
  </div>
  <div #view *ngIf="item">
      <div id="container-item" class="gallery-graphics-item">
        <i id="type-item" class="fas fa-2x fa-{{GALLERY_GRAPHICS_TYPES[item.type]}} {{item.type}}"></i>
        <h2 id="title-item" class="row-item def_back"
            [innerHTML]="('general.lang' | translate) == 'it' ? item.title : item.titleEn"></h2>
        <div id="body-item">
          <div id="wrapper-item" *ngIf="item.type == 'renders' || item.type == 'others'">

            <picture *ngIf="!isMobile || !isFullScreen">
              <source [srcset]="PUBLIC_GRAPHICS_IMAGES + 'webp/' + item.name
                + (!isMobile && isFullScreen ? '.max' : '') + '.webp'"  type="image/webp">
              <img draggable="false"
                   (swipeleft)="changeItem(itemNext?.name)"
                   (swiperight)="changeItem(itemPrev?.name)"
                   id="desktop-{{item.name}}" [src]="PUBLIC_GRAPHICS_IMAGES + item.name
                + (!isMobile && isFullScreen ? '.max' : '') + GALLERY_FILE_EXT" alt="{{item.title}}">
            </picture>
            <!--<img draggable="false" *ngIf="!isMobile || !isFullScreen"
                 (swipeleft)="changeItem(itemNext?.name)"
                 (swiperight)="changeItem(itemPrev?.name)"
                 id="desktop-image" [src]="PUBLIC_GRAPHICS_IMAGES + item.name
                + (!isMobile && isFullScreen ? '.max' : '') + GALLERY_FILE_EXT" alt="desktop-image" />-->
            <img draggable="false" *ngIf="isMobile && isFullScreen"
                 id="mobile-{{item.name}}" [src]="PUBLIC_GRAPHICS_IMAGES + item.name + GALLERY_FILE_EXT" alt="{{item.title}}" />
            <i id="expand" class="fa-solid fa-expand"
               *ngIf="showFullScreenButton && !isFullScreen && item.type == 'renders'" (click)="openFullscreen()"></i>
            <i id="compress" class="fa-solid fa-compress"
               *ngIf="showFullScreenButton && isFullScreen" (click)="closeFullscreen()"></i>
            <i id="expand-mobile" class="fa-solid fa-expand d-sm-block d-md-none"
               *ngIf="showFullScreenButton && !isFullScreen && item.type == 'renders'" (click)="openFullscreen(true)"></i>
            <i id="compress-mobile" class="fa-solid fa-compress d-sm-block d-md-none"
               *ngIf="showFullScreenButton && isFullScreen" (click)="closeFullscreen(true)"></i>
          </div>
          <iframe *ngIf="item.type == 'videos' && item.downloads" style="border: 0 none;"

                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  width="100%" height="315" [src]="item.downloads | safe" allowfullscreen></iframe>
        </div>

        <!--/************** BREADCRUMBS ***********/-->
        <app-breadcrumbs [nodes]="[{label: 'Graphics', url: 'gallery-graphics'},
            {label: item.type, url: 'gallery-graphics/' + item.type},
            {label: ('general.lang' | translate) == 'it' ? item.title : item.titleEn}]"></app-breadcrumbs>

        <!--/************** INFOS ***********/-->
        <div class="row-item row-border-top">
          <p class="description"
             [innerHTML]="('general.lang' | translate) == 'it' ? item.description : item.descriptionEn"></p>
          <p id="graphic_date">{{item.added | date:'dd/MM/yyyy' }}</p>
        </div>

        <!--/*********** TAGS ***********/-->
        <div class="row-item row-border-top">
          <a *ngFor="let tag of itemTags" class="tags" [href]="'/search/.?q=' + tag" tabindex="-1"
             rel="noopener noreferrer nofollow">
            <span>{{tag}}</span>
          </a>
        </div>

        <!--/************** DOWNLOADS ***********/-->
<!--        <div *ngIf="item.downloads?.length > 0 && item.type != 'videos' && getTypeOf(item.downloads) !== 'string'"-->
<!--             class="row-item row-border-top download-container">-->
<!--          <button type="button" class="input-link download-link" *ngFor="let download of item.downloads"-->
<!--                  [disabled]="downloaded === download" (click)="loadingDownload = !saveItem(item.name, download)">-->
<!--            <span class="input-icon-text">{{download}} </span>-->
<!--            <i *ngIf="!loadingDownload" class="fas fa-download"></i>-->
<!--            <i *ngIf="loadingDownload" class="fas fa-spinner fa-spin"></i>-->
<!--          </button>-->
<!--        </div>-->
      </div>

    </div>
</app-modal>

import {Model} from './Model';
import {Comment} from './Comment';

export class ModelsItem {
  constructor(
    public items: Model,
    public related: Model[],
    public comments: Comment[],
    public num: number,
    public number3d: number,
    public countDownload: number,
    public collection: boolean,
    public lods: boolean,
  ) {}

  public static adapt(item: any): ModelsItem | null {
    return (item?.items != null) ? new ModelsItem(
      Model.adapt(item.items),
      item?.related ? item.related.map(Model.adapt) : null,
      item?.comments ? item.comments.map(Comment.adapt) : null,
      item?.num,
      item?.number3d,
      item?.countDownload,
      item?.collection,
      item?.lods,
    ) : null;
  }
}


export class Comment {

  constructor(
    public id: number,
    public argument: string,
    public username: string,
    public message: string,
    public writeDate: string,
    // public ip: string
  ) {}

  public static adapt(item: any): Comment {
    return new Comment(
      item?.id,
      item?.argument,
      item?.username,
      item?.message,
      item?.write_date || item?.writeDate,
      // item?.ip,
    );
  }
}

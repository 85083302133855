
export const TITLE_APPEND = ' | SandBay';
export const OBYTE_HOST_UTIL = 'https://tools.obyte.it/';
export const OBYTE_HOST = 'https://www.sandbay.it/';
export const WHITELISTED_DOMAINS = ['idrobyte.com', 'idrobyte.it', 'obyte.it', 'sandbay.it'];

export const GALLERY_MODELS_DESCRIPTION = '3D models gallery for renders and games. From the smallest accessory to immense constructions, creativity finds space and clay.';
export const GALLERY_WEBSITES_DESCRIPTION = 'Web sites gallery. Technology at the service of online construction, whether it\'s an e-commerce or a showcase site.';
export const GALLERY_GRAPHICS_DESCRIPTION = 'Graphics gallery with renders and videos. Unknown places and dreamlike atmospheres in which to get lost or find yourself.';
export const GALLERY_TOOLS_DESCRIPTION = 'Tools and games gallery. Direction and automation mix with code and art to support the most complex tasks.';
export const ABOUT_DESCRIPTION = 'This page is about me and my projects. SandBay was created for the purpose of bringing to the world all the possibilities that technology can offer.';

export const PUBLIC_GRAPHICS_IMAGES = OBYTE_HOST_UTIL + 'public/graphics/images/';
export const GALLERY_ITEMSPERPAGE_LIST = [10, 15, 25, 50];
export const GALLERY_ITEMSPERPAGE_DEFAULT = 15;
// public static COMMENTS_ITEMSPERPAGE = 10;
export const PUBLIC_MODELS_IMAGES_3D = OBYTE_HOST_UTIL + 'public/models/images/';
export const PUBLIC_SLIDERS_IMAGES = OBYTE_HOST_UTIL + 'public/sliders/';
export const PUBLIC_TOOLS_IMAGES = OBYTE_HOST_UTIL + 'public/tools/images/';
export const GALLERY_IMAGE_VERSION = '?v=1.3';
export const GALLERY_FILE_EXT = '.jpg' + GALLERY_IMAGE_VERSION;
export const GALLERY_FILE_EXT_NEW = '.webp' + GALLERY_IMAGE_VERSION;
export const GALLERY_MIN_FILE_EXT = '.min' + GALLERY_FILE_EXT;
export const GALLERY_MODELS_TYPES = {
  'buildings': 'vihara', // torii-gate
  // 'characters': 'user-astronaut', // 'user-shield', // 'male', 'walking', // 'child',
  'weapons': 'crosshairs',
  'objects': 'chess', // 'chess-pawn', // dice-d6
  'animals': 'paw',
  'characters': 'dragon',
  'monsters': 'dragon',
  'vehicles': 'truck-monster', // rocket
  'foods': 'utensils',
};
// export const GALLERY_TOOLS_TYPES = ['tools', 'games', 'beta'];
export const GALLERY_GRAPHICS_TYPES = {
  'renders': 'dice-d6',
  'videos': 'film',
  'others': 'map',
};
export const GALLERY_TOOLS_TYPES = {
  'tools': 'toolbox',
  'games': 'gamepad',
  'beta': 'flask',
};
export const DEFAULT_LANG = 'en';
export const PORTALS_LANGUAGES = ['en', 'it', 'es', 'pirate'];
export const PORTALS_LANG_SWITCH = OBYTE_HOST + 'assets/i18n/';
// export const PORTALS_LANG_SWITCH_ABOUT = OBYTE_HOST_UTIL + 'portals/lang/about/';
export const EVENTS = [ // halloween christmas easter carnival
  {event: 'carnival', start: '02/01', end: '03/01', yearAdd: 0, alt: 'Carnival mask'},
  // {event: 'april', start: '04/02', end: '05/02', yearAdd: 0, alt: 'April Fools\' Day'},
  {event: 'easter', start: '04/02', end: '05/02', yearAdd: 0, alt: 'Easter egg'},
  // {event: 'august', start: '08/01', end: '08/20', yearAdd: 0, alt: 'August'},
  {event: 'halloween', start: '10/01', end: '11/15', yearAdd: 0, alt: 'Halloween Jack'}, // 11/02
  // {event: 'thanksgiving', start: '11/03', end: '11/16', yearAdd: 0, alt: 'Thanksgiving'},
  // {event: 'black', start: '11/17', end: '11/24', yearAdd: 0, alt: 'Black friday'},
  {event: 'christmas', start: '11/25', end: '01/02', yearAdd: 1, alt: 'Christmas hat'},
];

export const PORTALS_CONDITIONS = OBYTE_HOST_UTIL + 'public/docs/condizioniUtilizzo_'; // 'en.html';

/**** USERS ****/
export const AUTH_HEADER = 'Authorization';
// export const AUTH_PROTECTED_METHODS = ['user/pass/change/', 'model/', 'like/', 'user/profile/', 'user/website/'];
export const AUTH_PROTECTED_METHODS = ['passwordModify', 'getModelsItem', 'sendLike', 'getProfile', 'addWebSite'];
export const REG_WEBSITE_REGEX = '(https?://)?(([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?)?';
export const MIN_PASS_LENGTH = 3;

export const PATHS = {
  HOME: 'home',
  ABOUT: 'about',
  GALLERY_SEARCH: 'search',
  GALLERY_MODELS: 'gallery-models',
  GALLERY_GRAPHICS: 'gallery-graphics',
  GALLERY_TOOLS: 'gallery-tools',
  GALLERY_WEBSITES: 'gallery-websites',
};

/**** CACHE SYSTEM ****/
export const CACHE_SERVICE_LIST = [
  'getModels?', // ? to avoid getModelsItem.
  'getGraphics',
  'getTools',
  'getSliderNg',
  'getWebsites',
];
export let CACHE_TYPE = 'var'; // none session local var

export interface FiltersConfig {
  itemsPerPage: number;
  collections: boolean;
  links: boolean;
  lods: boolean;
  isFiltered: boolean;
}

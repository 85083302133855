import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {saveAs} from 'file-saver/dist/FileSaver.min.js';
import {ModelsItem} from '@/models/ModelsItem';
import {GraphicsItem} from '@/models/GraphicsItem';
import {ToolsMap} from '@/models/ToolsMap';
import {ToolsItem} from '@/models/ToolsItem';
import {Website} from '@/models/Website';
import {Models} from '@/models/Models';
import {map} from 'rxjs/operators';
import {environment} from '~/environments/environment';
import {GraphicsMap} from '@/models/GraphicsMap';
import {FiltersConfig} from '@/Constants';
// import {DOCUMENT} from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class GalleryService {
  private readonly api: string;

  constructor(
    private readonly _http: HttpClient) {
    // private _config: AppConfig) {
    this.api = environment.HOST_SERVICES; // this._config.get('HOST_SERVICES');
  }

  /**
   * Graphics function
   */
  getGraphics(): Observable<GraphicsMap> {
    return this._http.get<any>(`${this.api}getGraphicsNg`) // graphic/
      .pipe(map(res => GraphicsMap.adapt(res.result.items)) // res.result
    );
  }

  /**
   * Graphics function
   */
  getGraphicsItem(itemName: string): Observable<GraphicsItem> {
    const params = new HttpParams()
      .append('name', itemName);
    return this._http.get<any>(`${this.api}getGraphicsItemWithRelated`, // graphic/${itemName}/
      { params } ).pipe(
      map(res => GraphicsItem.adapt(res.result))
    );
  }

  /**
   * Models function
   */
  getModels(category: string = '', page: number = 1,
            configs?: FiltersConfig): Observable<Models> {
    let params = new HttpParams()
      .append('category', category)
      .append('page', String(page))
      .append('items', String(configs.itemsPerPage));
    if (configs.isFiltered) {
      if (configs.collections) { params = params.append('collection', 'filtered'); }
      if (configs.links) { params = params.append('download', 'filtered'); }
      if (configs.lods) { params = params.append('lods', 'filtered'); }
    }

    return this._http.get<any>(`${this.api}getModels`, // model/
      { params } ).pipe(
      map(res => Models.adapt(res.result))
    );
  }

  /**
   * Models item function
   */
  getModelsItem(itemName: string, itemNumber: number): Observable<ModelsItem> {
    let params = new HttpParams()
      .append('name', itemName);
    if (itemNumber > 1) {
      params = params.append('number', String(itemNumber));
    }
    return this._http.get<any>(`${this.api}getModelsItemWithRelated`, // model/${itemName}/
      { params } ).pipe(
      map(res => ModelsItem.adapt(res.result))
    );
  }

  /**
   * Tools function
   */
  getTools(): Observable<ToolsMap> {
    return this._http.get<any>(`${this.api}getTools`).pipe( // tool/
      map(res => ToolsMap.adapt(res.result.items)) // res.result
    );
  }

  /**
   * Tools function
   */
  getToolsItem(itemName: string): Observable<ToolsItem> {
    const params = new HttpParams()
      .append('name', itemName);
    return this._http.get<any>(`${this.api}getToolsItem`, // tool/${itemName}/
      { params } ).pipe(
      map(res => ToolsItem.adapt(res.result))
    );
  }

  /**
   * Websites function
   */
  getWebsites(): Observable<Website[]> {
    return this._http.get<any>(`${this.api}getWebsites`) // website/
      .pipe(map(res => res.result.items.map(Website.adapt)) // res.result
    );
  }

  /**
   * Utility function
   */
  downloadFile(item: string, format: string = ''): boolean {
    let params = new HttpParams()
      .append('name', item);
    if (format.length > 0) {
      params = params.append('format', format);
    }
    const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      return new Blob(byteArrays, { type: contentType });
    };
    this._http.get<any>(this.api + 'downloadFile', { params } )
      .toPromise().then(res => {
      if (res && res.result && res.result.base64) {
        const result = res.result;
        const blob = b64toBlob(result.base64, 'application/octet-stream' );
        saveAs( blob, result.filename );
      }
    });
    return true;
  }

  /**
   * Utility function
   */
  sendLike(itemName: string): Observable<any> {
    const params = new HttpParams()
      .append('type', 'GALLERY_MODELS')
      .append('name', itemName);
    return this._http.post(`${this.api}sendLike`, params ); // like/
  }

  /**
   * Utility function
   */
  search(keywords: string): Observable<any> {
    const params = new HttpParams()
      .append('type', 'GALLERY')
      .append('keywords', keywords);
    return this._http.get(`${this.api}search`, { params } ); // search/
  }
}

import {Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Meta, Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {GALLERY_FILE_EXT, GALLERY_GRAPHICS_TYPES, PUBLIC_GRAPHICS_IMAGES, TITLE_APPEND} from '@/Constants';
import {GalleryService} from '@galleries/services/gallery.service';
import {Graphic} from '@/models/Graphic';
import {UtilsService} from '@/services/utils.service';
import {Logger} from '@/services/logger.service';
import {TranslateService} from '@ngx-translate/core';
import {Clipboard} from '@angular/cdk/clipboard';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-gallery-graphics-item',
  templateUrl: './gallery-graphics-item.component.html',
  styleUrls: ['./_gallery-graphics-item.component.scss']
})
export class GalleryGraphicsItemComponent implements OnInit, OnDestroy {
  PUBLIC_GRAPHICS_IMAGES = PUBLIC_GRAPHICS_IMAGES;
  GALLERY_FILE_EXT = GALLERY_FILE_EXT;
  GALLERY_GRAPHICS_TYPES = GALLERY_GRAPHICS_TYPES;
  item: Graphic;
  itemPrev: Graphic;
  itemNext: Graphic;
  itemNumberGraphic: number;
  itemTags: string[];
  private _itemName: string;
  private _paramsSubscription: Subscription;
  private _itemSubscription: Subscription;
  loadingDownload = false;
  galleryError: boolean;
  galleryNotFound: boolean;
  downloaded = '';
  elem: any;
  isFullScreen: boolean;
  isMobile: boolean;
  showFullScreenButton: boolean;

  constructor(private _title: Title,
              private _meta: Meta,
              private _router: Router,
              private _activatedRoute: ActivatedRoute,
              private _utilsService: UtilsService,
              private _galleryService: GalleryService,
              private _translate: TranslateService,
              @Inject(DOCUMENT) private document: any) {}

  ngOnInit() {
    this._paramsSubscription = this._activatedRoute.params.subscribe((params: Params) => {
      this._itemName = params.name;
      this.getItem(params.name);

      this.checkScreenMode();
    });
  }

  /**
   * Main method to get element.
   */
  getItem(itemName: string) {
  this._itemSubscription = this._galleryService.getGraphicsItem(itemName)
    .subscribe({
      next: res => {
        if (res != null) {
          this.item = res.items;
          this.itemPrev = res.related.prev;
          this.itemNext = res.related.next;
          this.itemNumberGraphic = res.numberGraphic;
          this.itemTags = this.item.tags;
          this.item.downloads = res.items.downloads;
          const pageTitle = (this._translate.instant('general.lang') === 'it' ? this.item.title : this.item.titleEn);
          this._title.setTitle(`Graphics - ${pageTitle}${TITLE_APPEND}`);
          this._meta.updateTag({property: 'og:title', content: `Graphics - ${pageTitle}${TITLE_APPEND}`});

          // Description for search engines.
          let description = `Graphics: ${pageTitle}.`;
          if (this.item.descriptionEn) {
            // description = (this._translate.instant('general.lang') === 'it' ? this.item.description : this.item.descriptionEn);
            let descriptionTemp = this.item.descriptionEn;
            descriptionTemp = descriptionTemp.replace('<br/>', ' ');
            // descriptionTemp = descriptionTemp.substring(0, descriptionTemp.indexOf('.') + 1);
            description = (descriptionTemp.length < 88) ? (description + ' ' + descriptionTemp) : descriptionTemp;
          }
          this._meta.updateTag({ name: 'description', content: description });
          this._meta.updateTag({property: 'og:description', content: description});
          // this._meta.updateTag({ name: 'description', content: this.item.descriptionEn });
          // this._meta.updateTag({property: 'og:description', content: this.item.descriptionEn});
          this._meta.updateTag({property: 'og:image',
            content: this.item.type === 'videos'
              ? `https://img.youtube.com/vi/${this.item.thumbYoutube}/mqdefault.jpg`
              : PUBLIC_GRAPHICS_IMAGES + this.item.name + GALLERY_FILE_EXT});
          this._meta.updateTag({property: 'og:url', content: `https://www.sandbay.it/gallery-graphics/asset-graphic/${this._itemName}/`});
          this._utilsService.createLinkForCanonicalURL(`https://www.sandbay.it/gallery-graphics/asset-graphic/${this._itemName}/`);
          // this._title.setTitle(this.item.title + TITLE_APPEND);
        } else {
          this.galleryNotFound = true;
        }
      },
      error: err => {
          this.galleryError = UtilsService.handleGalleryError(err);
      }
    });
  }

  ngOnDestroy() {
    this._paramsSubscription.unsubscribe();
    this._itemSubscription.unsubscribe();
  }

  /**
   * Navigate to other related item.
   */
  changeItem(paramName) {
    if (paramName) {
      void this._router.navigate([{outlets: {asset: ['graphic', paramName]}}]);
    }
  }

  saveItem(itemName: string, format: string): boolean {
    this.downloaded = format;
    Logger.log(`Downloading ${format}...`);
    return this._galleryService.downloadFile(itemName, format);
  }

  /**
   * Close model item modal (also remove fullscreen mode if active).
   */
  close() {
    if (this.isFullScreen) {
      this.closeFullscreen();
    }
    this._title.setTitle(`Graphics${TITLE_APPEND}`);
    this._meta.updateTag({ name: 'description', content: `Graphics gallery with renders, marketing and videos.` });
    this._meta.updateTag({property: 'og:title', content: `Graphics${TITLE_APPEND}`});
    this._meta.updateTag({property: 'og:description', content: `Graphics gallery with renders, marketing and videos.`});
    this._meta.updateTag({property: 'og:image', content: 'https://www.sandbay.it/assets/logo.png'});
    this._meta.updateTag({property: 'og:url', content: `https://www.sandbay.it/gallery-graphics/`});
    this._utilsService.createLinkForCanonicalURL(`https://www.sandbay.it/gallery-graphics/`);
    void this._router.navigate([{ outlets: { asset: null } }]);
  }

  getTypeOf(typedVar) {
    return typeof(typedVar);
  }

  /**
   * Fullscreen.
   */
  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:webkitfullscreenchange', ['$event'])
  @HostListener('document:mozfullscreenchange', ['$event'])
  @HostListener('document:MSFullscreenChange', ['$event'])
  fullscreen_modes() { // (event) {
    this.checkScreenMode();
  }
  checkScreenMode() {
    if (typeof document !== 'undefined') {
      this.elem = document.documentElement;
      this.isFullScreen = !!document.fullscreenElement;

      if (typeof screen !== 'undefined' && screen.orientation && screen.orientation.lock) {
        this.showFullScreenButton = true;
      }
    }
  }
  openFullscreen(mobile = false) {
    this.isMobile = mobile;
    UtilsService.openFullscreen(this.elem, mobile);
  }
  closeFullscreen(mobile = false) {
    UtilsService.closeFullscreen(this.document, mobile);
  }
}

<div id="container" class="fadeIn def_back container-md">
  <div id="container-border"></div>
  <div id="container-bottom"></div>
  <h1 id="title-404" class="titles">{{code}}</h1>
  <h2 class="titles">{{errorTitle}}</h2>
  <p class="mb-4">{{errorDesc}}</p>
  <p *ngIf="path && code === '404'">
    Maybe you were looking for the <a [routerLink]="path + '/.'">{{ path }}</a> page.
  </p>
</div>

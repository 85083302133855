export class Tool {

  constructor(
    public icon: boolean,
    public name: string,
    public title: string,
    public type: string,
    public year: number,
    // public external: string,
    // public internal: string,
    public link: string,
    public desc: string,
    public descEn: string,
    public descriptionExt?: string,
    public descriptionExtEn?: string,
    public page?: string,
    public image?: string,
  ) {}

  public static adapt(item: any): Tool {
    return new Tool(
      item?.icon,
      item?.name,
      item?.title,
      item?.type,
      item?.year,
      item?.link,
      item?.description,
      item?.description_en || item?.descriptionEn,
      item?.description_ext || item?.descriptionExt,
      item?.description_ext_en || item?.descriptionExtEn,
      item?.page,
      item?.image,
    );
  }
}

import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  @Input() nodes: {label: string, url?: string}[];
  @Input() show = false;

  constructor() { }

  ngOnInit(): void {
  }
}

export class Website {
  /*{"title":"Motofficina PitStop","path":"motofficinapitstop\/index.php","name":"motofficinaPitStop","year":"2011","link":""}*/

  constructor(
    public name: string,
    public title: string,
    public path: string,
    public description: string,
    public descriptionEn: string,
    public year: number,
    public link: string,
    public type: string,
    public state: string,
    public old: boolean,
    public draft: boolean,
  ) {}

  public static adapt(item: any): Website | null {
    return item ? new Website(
      item.name,
      item.title,
      item.path,
      item.description,
      item.description_en || item.descriptionEn,
      item.year,
      item.link,
      item.type,
      item.state,
      item.old,
      item.draft,
    ) : null;
  }
}

import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: '[app-home-exa]',
  templateUrl: './home-exa.component.html',
  styleUrls: ['./_home-exa.component.scss']
})
export class HomeExaComponent implements OnInit {
  @Input() section = {
    link: '/gallery-models',
    icon: 'fa-cubes fas',
    title: 'sez3dModels',
    desc: 'desc3dModels',
    img: 'models'
  };
  @Input() i = 0;
  rotated = false;

  constructor() { }

  ngOnInit(): void {
  }

}

export class Graphic {
  /*{"id":"47","name":"render_ice_mobs","title":"Mostri del Ghiaccio","desc":"...",
  "type":"renders","downloads":"800x600|1024x768|1280x1024|1366x768","added":"0000-00-00 00:00:00"}*/

  constructor(
    public name: string,
    public title: string,
    public titleEn: string,
    public type: string,
    public added: string,
    public tags: string[],
    public thumbYoutube?: string,
    public description?: string,
    public descriptionEn?: string,
    public downloads?: string[],
  ) {}

  public static adapt(item: any): Graphic | null {
    return item ? new Graphic(
      item.name,
      item.title,
      item.title_en || item.titleEn,
      `${item.type}s`,
      item.added,
      item.tags,
      item.thumb_youtube || item.thumbYoutube,
      item.description,
      item.description_en || item.descriptionEn,
      (item.downloads && typeof item.downloads === 'string') ? item.downloads.split('|') : null,
    ) : null;
  }
}

import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {UsersService} from './users.service';
import {AUTH_HEADER} from '@/Constants';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private _authService: UsersService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log('hasToken:' + this._authService.isAuthenticated());

    if (this._authService.isAuthenticated() && UsersService.isProtected(request.url.toString())) {
      const token = UsersService.getToken();
      request = request.clone({
        headers: request.headers.set(AUTH_HEADER, `${token}`) // `Bearer ${token}`)
      });
      return next.handle(request);
    }

    return next.handle(request);
  }

}

// modules
import {Injectable, NgModule} from '@angular/core';
import {BrowserModule, provideClientHydration, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgcCookieConsentModule} from 'ngx-cookieconsent';
import {APP_BASE_HREF} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
// import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import {NgbCarouselModule} from '@ng-bootstrap/ng-bootstrap';
// generic components
import {AppComponent} from './app.component';
import {ErrorComponent} from './components/error/error.component';
import {HomeComponent} from './components/home/home.component';
import {AboutUsComponent} from './components/about-us/about-us.component';
// common
import {HeaderComponent} from './components/common/header/header.component';
import {MenuComponent} from './components/common/menu/menu.component';
import {FooterComponent} from './components/common/footer/footer.component';
import {BackgroundsComponent} from './components/common/backgrounds/backgrounds.component';
import {SearchComponent} from './components/common/search/search.component';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {GalleriesModule} from '@galleries/galleries.module';
import {cookieConfig, HttpLoaderFactory, SharedModule} from './shared.module';
import {NavigationEnd, Router, RouterModule, Routes, UrlSerializer} from '@angular/router';
import {GalleryService} from '@galleries/services/gallery.service';
import {JwtModule, JwtModuleOptions} from '@auth0/angular-jwt';
import {StorageService} from './services/storage.service';
import {WHITELISTED_DOMAINS} from './Constants';
import {AuthInterceptor} from '@users/services/auth.interceptor';
import {HomeExaComponent} from './components/home/home-exa.component';
import {TogglesComponent} from './components/common/toggles/toggles.component';
import {CustomUrlSerializer} from '@/services/url-serializer';
// import * as Hammer from 'hammerjs';

export function tokenGetter() {
  return StorageService.get('token');
}

const MODULE_JWT_OPTIONS: JwtModuleOptions = {
  config: {
    'tokenGetter': tokenGetter,
    allowedDomains: WHITELISTED_DOMAINS,
    // 'whitelistedDomains': WHITELISTED_DOMAINS,
  }
};

// making hammer config (3)
@Injectable({
  providedIn: 'root',
})
export class MyHammerConfig extends HammerGestureConfig  {
  overrides = {
    'pinch': { enable: false },
    'rotate': { enable: false },
    'swipe': {velocity: 0.4, threshold: 20} // override default settings { direction: Hammer.DIRECTION_ALL },
  } as any;
}

const routes: Routes = [
  { path: '.', component: HomeComponent, pathMatch: 'full' },
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'home/.', redirectTo: '', pathMatch: 'full' },
  { path: 'about/.', component: AboutUsComponent, pathMatch: 'full' },
  {
    path: 'user',
    loadChildren: () => import ('./users/users.module').then(m => m.UsersModule)
  },
  { path: 'error/:code', component: ErrorComponent },
  { path: '**', component: ErrorComponent }
];

@NgModule({
  declarations: [
    HeaderComponent,
    SearchComponent,
    MenuComponent,
    FooterComponent,
    BackgroundsComponent,
    AppComponent,
    HomeComponent,
    ErrorComponent,
    AboutUsComponent,
    HomeExaComponent,
    TogglesComponent,
  ],
  imports: [
    SharedModule, // custom
    GalleriesModule, // custom
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      // Restore the last scroll position
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 0],
      // Enable scrolling to anchors
      anchorScrolling: 'enabled'
    }),
    // AppRoutingModule, // custom
    // BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    NgbCarouselModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    JwtModule.forRoot(MODULE_JWT_OPTIONS),
    HammerModule
  ],
  providers: [
    provideClientHydration(),
    GalleryService,
    { provide: APP_BASE_HREF, useValue: '/' },
    // { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: UrlSerializer, useClass: CustomUrlSerializer }, // To change router outlets.
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig }
  ],
  exports: [],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
  constructor(private _router: Router) {
    // Reuse service data fix https://stackoverflow.com/questions/40983055/how-to-reload-the-current-route-with-the-angular-2-router
    // this._router.routeReuseStrategy.shouldReuseRoute = function(){
    //   return false;
    // };
    if (typeof window !== 'undefined') {
      this._router.events.subscribe((evt) => {
        if (evt instanceof NavigationEnd) {
          // this._router.navigated = false;
          window.scrollTo(0, 0);
          (window as any).ga('set', 'page', evt.urlAfterRedirects);
          (window as any).ga('send', 'pageview');
        }
      });
    }
  }
}

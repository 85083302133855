import {Directive, EventEmitter, Injectable, Output} from '@angular/core';

@Directive()
@Injectable({
  providedIn: 'root',
})
export class BackgroundDirective {

  constructor() {}

  @Output() backgroundsEvent: EventEmitter<string> = new EventEmitter();

  switchTheme(theme: string) {
    console.log('Switch theme:', theme);
    this.backgroundsEvent.emit(theme);
  }
}

import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {UntypedFormBuilder, Validators} from '@angular/forms';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./_search.component.scss']
})
export class SearchComponent implements OnInit {
  public inputLoading = false;
  @ViewChild('searchField', {static: true}) searchField: ElementRef;
  searchFormGroup = this.fb.group({
    keywords: ['', [ Validators.required ]],
  });

  constructor(private _router: Router,
              private fb: UntypedFormBuilder) {}

  ngOnInit() {
    this.searchField.nativeElement.focus();
  }

  /**
   * Submit search form. Searching for something.
   */
  onSubmit() {
    const formValues = this.searchFormGroup.value;
    // this.inputLoading = false;
    this._router.navigateByUrl('/search/.?q=' + formValues.keywords);
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {BackgroundDirective} from '@users/services/background.service';

@Component({
  selector: 'app-backgrounds',
  templateUrl: './backgrounds.component.html',
  styleUrls: [
    './_backgrounds.space.scss',
    './_backgrounds.snow.scss',
    './_backgrounds.sea.scss',
    './_backgrounds.nature.scss'
  ]
})
export class BackgroundsComponent implements OnInit, OnDestroy {
  public theme = 'standard'; // default: 'space';

  constructor(private backgroundDirective: BackgroundDirective) { }

  ngOnInit() {
    this.backgroundDirective.backgroundsEvent.subscribe(theme => {
      this.theme = theme;
    });
  }

  ngOnDestroy(): void {
    this.backgroundDirective.backgroundsEvent.unsubscribe();
  }
}

<a class="tools_rows {{tool.type | lowercase}}" rel="{{tool.link ? 'noopener noreferrer nofollow' : ''}}"
   (click)="showItem(tool.name, tool.link)" [routerLink]="tool.link ? null : [{ outlets: { asset: ['tool', tool.name] } }]">
  <div class="tool_header">
    <span class="tools_titles {{tool.type | lowercase}}" [innerHTML]="tool.title"></span>

    <i *ngIf="tool.link" class="fas fa-external-link-alt"></i>
    <i class="fab {{(tool.type !== 'PHP' && tool.type !== 'android') ? 'fa-25x' : 'fa-2x'}}
        fa-{{tool.type | lowercase}} {{tool.type | lowercase}} tools_types"></i>
  </div>
  <p>
    <span *ngIf="tool.icon && !tool.image" class="tools_imgs background" id="tools-{{tool.name}}"></span>
    <span *ngIf="tool.image" class="tools_imgs">
      <picture>
        <source [srcset]="PUBLIC_TOOLS_IMAGES + 'webp/' + tool.image.replace('jpg', 'webp').replace('png', 'webp')"
                type="image/webp">
        <img draggable="false" [src]="PUBLIC_TOOLS_IMAGES + tool.image"
             [alt]="tool.title" width="100%" height="100%">
      </picture>
      <!--<img draggable="false" loading="lazy" [src]="PUBLIC_TOOLS_IMAGES + tool.image"
           width="100%" height="100%" alt="{{tool.name}}"/>-->
    </span>
    <span class="tools_desc" [innerHTML]="('general.lang' | translate) == 'it' ? tool.desc : tool.descEn"></span>
  </p>
  <span class="tools_year def_back">{{tool.year}}</span>
  <!--<p [innerHTML]="('tools.' + toolType + '-' + tool.name) | translate"></p>-->
</a>

import {Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Title} from '@angular/platform-browser';

import {UtilsService} from '@/services/utils.service';

export enum MODAL_KEYS {
  DELETE = 'Delete',
  ESCAPE = 'Escape',
  RIGHT_ARROW = 'ArrowRight',
  LEFT_ARROW = 'ArrowLeft',
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./_modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [trigger('modal', [
    state('in', style({ transform: 'scale3d(.0, .0, .0)' })),
    transition('void => *', [
      style({ transform: 'scale3d(.3, .3, .3)' }),
      animate(150)
    ])
  ])]
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() visible: boolean;
  @Input() fullscreen: boolean;
  public unmount = false;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() next = new EventEmitter<void>();
  @Output() previous = new EventEmitter<void>();
  private previousTitle = '';

  constructor(private _title: Title,
              private _el: ElementRef) { }

  ngOnInit() {
    UtilsService.lockScroll();
    this.previousTitle = this._title.getTitle();
    this._el.nativeElement.focus(); // Focus on modal for screen readers.
  }

  ngOnDestroy() {
    UtilsService.unlockScroll();
    this._title.setTitle(this.previousTitle);
  }

  close() {
    this.unmount = true;
    UtilsService.unlockScroll();
    this.visibleChange.next(false);
    this.visible = false;
    // setTimeout(() => {
    //   UtilsService.unlockScroll();
    //   this.visibleChange.next(false);
    //   this.visible = false;
    // }, 500);
  }

  onSwipeLeft() {
    this.next.emit();
  }

  onSwipeRight() {
    this.previous.emit();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: any) {
    if (event.key === MODAL_KEYS.ESCAPE || event.key === MODAL_KEYS.DELETE) {
      this.close();
    }

    if (event.key === MODAL_KEYS.RIGHT_ARROW) {
      this.next.emit();
    }

    if (event.key === MODAL_KEYS.LEFT_ARROW) {
      this.previous.emit();
    }
  }
}

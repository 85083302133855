<app-modal [visible]="true" (visibleChange)="close()">
  <div *ngIf="!item && !galleryError && !galleryNotFound" class="lds-css">
    <app-loading></app-loading>
  </div>
  <div *ngIf="!item && galleryError" class="gallery-error">
    <i class="far fa-times-circle"></i>
    {{'errors.UNKNOWN' | translate}}
  </div>
  <div *ngIf="!item && galleryNotFound" class="gallery-error">
    <div class="error-code">404</div>
    <div>{{'errors.NOT_FOUND' | translate}}</div>
  </div>
  <div #view *ngIf="item">
    <div id="container-item" class="gallery-tools-item">
      <i id="type-item" class="fab fa-2x fa-{{item.type | lowercase}} {{item.type | lowercase}}"></i>
      <h2 id="title-item" class="row-item def_back">{{item.title}}</h2>
      <div id="body-item">

        <picture>
          <source [srcset]="PUBLIC_TOOLS_IMAGES + 'webp/' + item.name + '.webp'" type="image/webp">
          <img draggable="false" *ngIf="item.titleImgs == '1' && !item.image"
               [src]="PUBLIC_TOOLS_IMAGES + item.name + TOOL_FILE_EXT" [alt]="item.title">
          <img draggable="false" *ngIf="item.titleImgs == '1' && item.image"
               [src]="PUBLIC_TOOLS_IMAGES + item.name + GALLERY_FILE_EXT" [alt]="item.title">
        </picture>

        <!--<img draggable="false" loading="lazy" *ngIf="item.titleImgs == '1' && !item.image"
             [src]="PUBLIC_TOOLS_IMAGES + item.name + TOOL_FILE_EXT" alt="{{item.name}}" />
        <img draggable="false" loading="lazy" *ngIf="item.titleImgs == '1' && item.image"
             [src]="PUBLIC_TOOLS_IMAGES + item.name + GALLERY_FILE_EXT" alt="{{item.name}}" />-->
      </div>
      <div class="row-item row-border-top">
        <p id="tool-detail-{{item.name}}" class="tool-detail"
           [innerHTML]="('general.lang' | translate) == 'it' ? item.descriptionExt : item.descriptionExtEn"></p>
      </div>

      <div *ngIf="item.download" class="row-item row-border-top download-container">
        <!-- Download container -->
        <span id="download-size-container" class="col-4">
          <span id="download-size">{{ item.download }}</span>&nbsp;
          <i class="fas fa-server"></i>
        </span>
        <button type="button" class="input-link download-link"
                (click)="loadingDownload = !saveItem(item.name)">
          <span class="input-icon-text">Download </span>
          <i *ngIf="!loadingDownload" class="fas fa-download"></i>
          <i *ngIf="loadingDownload" class="fas fa-spinner fa-spin"></i>
        </button>
        <span id="download-count-container" class="col-4">
          <span id="download-count">{{ item.countDownload }}</span>&nbsp;
          <i class="fas fa-download"></i>
        </span>
      </div>

      <div *ngIf="item.donate" class="row-item row-border-top">
        <div id="donate-button">
          <form #donateButtonForm action="https://www.paypal.com/donate" method="post" target="_top" id="donate-button-form">
            <input type="hidden" name="hosted_button_id" value="6PGGXL2QMCB2L" />
<!--            <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" border="0"-->
<!--                   name="submit" title="Donate with PayPal button" alt="Donate with PayPal button"-->
<!--                   (click)="donateButtonForm.submit()" />-->
            <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
          </form>
          <button type="button" class="input-link" title="Donate a coffee with PayPal button"
                  (click)="donateButtonForm.submit()" tabindex="-1" rel="noopener noreferrer nofollow">
            <span class="input-icon-text">Donate a </span>
            <i class="fas fa-coffee"></i>
          </button>
        </div>
      </div>

      <div class="row-item row-border-top" *ngIf="itemVersions && ('general.lang' | translate) === 'it'">
        <!-- Version container -->
        <div *ngFor="let v of itemVersionNames; let i = index" [attr.data-index]="i" class="version_container">
          <h6 class="version_title">
            {{v + ' v' + itemVersions[i][0].version}} - {{item.year}}
          </h6>
          <div id="collapse{{i}}">
            <div *ngFor="let itemVersion of itemVersions[i]; let j = index" [attr.data-index]="j" class="row">
              <span class="version_v col-2" [class.first]="j == 0">v{{itemVersion.version}}</span>
              <div class="version_body col-7 col-sm-8" [innerHTML]="itemVersion.description"></div>
              <span class="version_write_date col-3 col-sm-2 px-0" [class.first]="j == 0">
                {{itemVersion.added | date:'dd/MM/yyyy' }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-modal>

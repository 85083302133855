import {Graphic} from './Graphic';

export class GraphicsItem {

  constructor(
    public items: Graphic | null,
    public related: Related | null,
    public numberGraphic: number
  ) {}

  public static adapt(item: any): GraphicsItem | null {
    return (item?.items != null) ? new GraphicsItem(
      item?.items ? Graphic.adapt(item.items) : null,
      item?.related ? Related.adapt(item.related) : null,
      item?.numberGraphic,
    ) : null;
  }
}

export class Related {
  constructor(
    public prev: Graphic | null,
    public next: Graphic | null
  ) {}

  public static adapt(item): Related {
    return new Related(
      item?.prev ? Graphic.adapt(item?.prev) : null,
      item?.next ? Graphic.adapt(item?.next) : null,
    );
  }
}

export enum ErrorEnum {
  ERROR = 'ERROR',
  USER_NOT_LOGGED = 'USER_NOT_LOGGED',
  DATA_EMPTY = 'DATA_EMPTY',
  INVALID_USER = 'INVALID_USER',
  INVALID_EMAIL = 'INVALID_EMAIL',
  INVALID_WEBSITE = 'INVALID_WEBSITE',
  INVALID_PASSWORD = 'INVALID_PASSWORD',
  INVALID_KEY = 'INVALID_KEY',
  INVALID_COND = 'INVALID_COND',
  USER_NOT_EXISTS = 'USER_NOT_EXISTS',
  USER_EXISTS = 'USER_EXISTS',
  DATA_ONE_EMPTY = 'DATA_ONE_EMPTY',
  LOCKED_EMAIL = 'LOCKED_EMAIL',
  UNKNOWN = 'UNKNOWN',
}

<div id="container" class="fadeIn def_back gallery-graphics container-md">
  <div id="container-border"></div>
  <div id="container-bottom"></div>
  <div class="row">
    <div class="col-3"></div>
    <h1 class="titles {{itemsCategory}} category col-6">
      <i *ngIf="itemsCategory" class="fas fa-13x fa-{{GALLERY_GRAPHICS_TYPES[itemsCategory]}}">&nbsp;</i>
      <span><span [innerHTML]="'menu.sezGraphics' | translate"></span>&nbsp;<br/></span>
      <span>{{ itemsCategory }}</span>
    </h1>
    <div class="col-3"></div>
  </div>

  <div class="row">
    <div id="page-description" class="col-12">
      <p [innerHTML]="('menu.graphicsDescription' + (itemsCategory | titlecase)) | translate" class="text-center mb-4"></p>
    </div>
  </div>

  <app-breadcrumbs [nodes]="[{label: 'Graphics', url: 'gallery-graphics'},
    {label: (itemsCategory | titlecase)}]"></app-breadcrumbs>

  <div class="{{itemsCategory}}" *ngIf="itemsCategory && thumbs">
    <div *ngIf="thumbs[itemsCategory].length === 0 && !galleryError" class="lds-css">
      <app-loading></app-loading>
    </div>
    <div *ngIf="thumbs[itemsCategory].length === 0 && galleryError" class="gallery-error">
      <i class="far fa-times-circle"></i>
      {{'errors.UNKNOWN' | translate}}
    </div>
    <div class="thumbs category">
      <div class="item" *ngFor="let elem of thumbs[itemsCategory]">
        <a rel="{{elem.type}}_group"
           (click)="showItem(elem.name)" [routerLink]="[{ outlets: { asset: ['graphic', elem.name] } }]">
          <span class="{{elem.type}}" id="{{elem.name}}">
            <picture *ngIf="itemsCategory !== 'videos'">
              <source [srcset]="PUBLIC_GRAPHICS_IMAGES + 'webp/' + elem.name + '.min.webp'"  type="image/webp">
              <img draggable="false" [src]="PUBLIC_GRAPHICS_IMAGES + elem.name + GALLERY_MIN_FILE_EXT"
                   [alt]="elem.title" width="180px" height="180px">
            </picture>
            <picture *ngIf="itemsCategory === 'videos'">
              <source [srcset]="'https://img.youtube.com/vi_webp/' + elem['thumbYoutube'] + '/mqdefault.webp'"  type="image/webp">
              <img draggable="false" [src]="'https://img.youtube.com/vi/' + elem['thumbYoutube'] + '/mqdefault.jpg'"
                   [alt]="elem.title" width="180px" height="101px">
            </picture>
            <i draggable="false" *ngIf="itemsCategory === 'videos'" class="fa-2x fa-solid fa-circle-play">&nbsp;</i>
          </span>
        </a>
        <p class="graphic-descs {{itemsCategory}}" [innerHTML]="('general.lang' | translate) == 'it' ? elem.title : elem['titleEn']"></p>
      </div>
    </div>
  </div>
</div>

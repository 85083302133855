import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';
import {CacheService} from './cache.service';
import {CACHE_SERVICE_LIST} from '@/Constants';


@Injectable()
export class CacheInterceptor implements HttpInterceptor {

  constructor(private cacheService: CacheService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // If not GET method invalidate cache to refresh services.
    if (req.method !== 'GET') {
      // console.log(`Invalidating cache: ${req.method} ${req.url}`);
      this.cacheService.invalidateCache();
      return next.handle(req);
    }
    // Selective cache (from services list).
    if (!CACHE_SERVICE_LIST.some(u => req.urlWithParams.includes(u))) {
      return next.handle(req);
    }

    // Search for a cached response.
    const cachedResponse: HttpResponse<any> = this.cacheService.get(req.urlWithParams);

    // return cached response
    if (cachedResponse) {
      // console.log(`Returning a cached response: ${cachedResponse.url}`);
      return of(cachedResponse);
    }

    // send request to server and add response to cache
    return next.handle(req)
      .pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            // console.log(`Adding item to cache: ${req.urlWithParams}`);
            this.cacheService.put(req.urlWithParams, event);
          }
        })
      );

  }
}

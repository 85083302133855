import {Component, Input, OnInit} from '@angular/core';
import {GALLERY_GRAPHICS_TYPES, GALLERY_MODELS_TYPES} from '@/Constants';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  // styleUrls: ['./_menu.component.scss']
})
export class MenuComponent implements OnInit {
  @Input() public headerFixed: boolean;
  public navOpen = false;
  public GALLERY_MODELS_TYPES = GALLERY_MODELS_TYPES;
  public MODELS_TYPES = Object.keys(GALLERY_MODELS_TYPES);
  public GALLERY_GRAPHICS_TYPES = GALLERY_GRAPHICS_TYPES;
  public GRAPHICS_TYPES = Object.keys(GALLERY_GRAPHICS_TYPES);

  constructor() {
    this.MODELS_TYPES.splice(5, 1);
  }

  ngOnInit() {}

  onNavOpen() {
    this.navOpen = true;
    if (typeof document !== 'undefined') {
      document.body.classList.add('mobile-hidden');
      // UtilsService.lockScroll();
    }
  }
  onNavClose() {
    this.navOpen = false;
    if (typeof document !== 'undefined') {
      document.body.classList.remove('mobile-hidden');
      // UtilsService.unlockScroll();
    }
  }

  /*public externalClick(link: string) {
    window.open(link, '_blank');
    this.navOpen = !this.navOpen;
  }*/
}

import {NgModule} from '@angular/core'; // APP_INITIALIZER
import {CommonModule} from '@angular/common';
import {UntypedFormControl, FormGroupDirective, FormsModule, NgForm, ReactiveFormsModule} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {NgcCookieConsentConfig} from 'ngx-cookieconsent';

import {ContactsComponent} from './components/common/contacts/contacts.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {PORTALS_LANG_SWITCH} from './Constants';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {UtilsService} from './services/utils.service';
import {ModalComponent} from './components/common/modal/modal.component';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {LoadingComponent} from './components/common/loading/loading.component';
import {CacheInterceptor} from './services/cache.interceptor';
import {OAuthModule} from 'angular-oauth2-oidc';
import {ClickOutsideDirective} from '@/services/click-outside.directive';

// import {AppConfig} from './app.config';

@NgModule({
  declarations: [
    ContactsComponent,
    ModalComponent,
    LoadingComponent,
    ClickOutsideDirective, // (clickOutside)
  ],
  imports: [
    HttpClientModule,
    OAuthModule.forRoot(),
    CommonModule,
    FormsModule, // For contacts component
    ReactiveFormsModule, // For contacts component
    MatInputModule, // For contacts component
  ],
  exports: [
    ContactsComponent,
    ModalComponent,
    LoadingComponent,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    NgbDropdownModule,
    ClickOutsideDirective, // (clickOutside)
  ],
  providers: [
    // AppConfig, // Async configuration
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initConfig,
    //   deps: [AppConfig],
    //   multi: true
    // },
    UtilsService,
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
  ]
})
export class SharedModule {}

/* TODO get lang from profile */
/**
 * Can use too custom loader to use a global javascript variable or use Google translate
 */
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, PORTALS_LANG_SWITCH, '.json?v=1.39');
}

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

// export function initConfig(config: AppConfig) {
//   return () => config.load();
// }

export const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: '.sandbay.it',
    secure: true,
  },
  position: 'bottom',
  theme: 'classic',
  palette: {
    popup: {
      background: 'rgb(38, 0, 56, .85)', // '#14907d',
      text: '#ffffff',
      link: '#ffffff'
    },
    button: {
      background: '#548d9c',
      text: '#000000',
      border: 'transparent'
    }
  },
  type: 'info',
    content: {
      message: 'SandBay uses cookies to ensure you get the best experience on our website.',
      dismiss: 'Ok!',
      deny: 'Refuse cookies',
      link: 'Learn more',
      href: 'https://tools.obyte.it/public/docs/privacy_policy_en.html',
      policy: 'Cookie Policy'
  }
};
